<template>
  <div id="coupons_detail">
    <Navbar current="Detalhes da empresa" :link="$paths.partners.list" :linkName="'Empresas parceiras'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes da empresa">
        <template v-slot:button>
          <button @click="handleDelete" class="btn btn-link text-danger ms-3">
            <router-link :to="`${$paths.partners.edit}/${item.id}`" class="btn btn-outline-secondary me-3">
              <i class="fa-solid fa-pencil me-1"></i>
              Editar
            </router-link>
            <i class="fa-solid fa-trash-alt me-1"></i>
            Remover
          </button>
        </template>
      </Header>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="row">
          <div class="col-12 col-xl-7">
            <div class="card">
              <ul class="nav nav-tabs ps-4 pt-3" id="myTab" role="tablist">
                <li class="nav-item active" role="presentation">
                  <button class="nav-link active" id="appointments-p-tab" data-bs-toggle="tab"
                    data-bs-target="#appointments-p" type="button" role="tab" aria-controls="appointments-p"
                    aria-selected="true">
                    Sessões realizadas
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="participants-tab" data-bs-toggle="tab" data-bs-target="#participants"
                    type="button" role="tab" aria-controls="participants" aria-selected="false">
                    Pacientes com código ativo
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="codes-tab" data-bs-toggle="tab" data-bs-target="#codes" type="button"
                    role="tab" aria-controls="codes" aria-selected="false">
                    Códigos
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="report_emails-tab" data-bs-toggle="tab" data-bs-target="#report_emails" type="button"
                    role="tab" aria-controls="report_emails" aria-selected="false">
                    Emails
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <!-- Appointments Tab -->
                <div class="tab-pane card-body fade show active" id="appointments-p" role="tabpanel"
                  aria-labelledby="appointments-p-tab">
                  <EmptyState v-if="!item?.appointments?.length" :totalItemsRegistered="item?.appointments?.length">
                    <template v-slot:noItemsRegistered>
                      <span class="fa-3x far fa-calendar-check text-black-50 mb-2"></span>
                      <p>Nenhuma sessão</p>
                    </template>
                  </EmptyState>
                  <template v-else>
                    <div class="table-responsive">
                      <table ref="myTable" class="table table-sm table-nowrap card-table table-hover cursor-pointer">
                        <TableHead :columns="appointmentColumns" />
                        <tbody class="list">
                          <AppointmentTableItem v-for="(appointment, index) in item?.appointments" :item="appointment"
                            :key="index" :index="index" :visibleColumns="[
                              'id',
                              'psi',
                              'patient',
                              'date',
                              'status',
                              'shortcut',
                            ]" />
                        </tbody>
                      </table>
                    </div>
                  </template>
                </div>
                <!-- Participants Tab -->
                <div class="tab-pane card-body fade" id="participants" role="tabpanel"
                  aria-labelledby="participants-tab">
                  <EmptyState v-if="!item?.patients?.length" :totalItemsRegistered="item?.patients?.length">
                    <template v-slot:noItemsRegistered>
                      <span class="fa-3x fas fa-user text-black-50 mb-2"></span>
                      <p>Nenhum paciente</p>
                    </template>
                  </EmptyState>
                  <template v-else>
                    <div class="table-responsive">
                      <table ref="myTable" class="table table-sm table-nowrap card-table table-hover cursor-pointer">
                        <TableHead :columns="userColumns" />
                        <tbody class="list">
                          <tr v-for="(item, index) in item?.patients" v-bind:key="'tr-' + index">
                            <td>
                              <img src="@/assets/img/avatar_placeholder.svg" class="avatar-img avatar-xs rounded-circle"
                                alt="" />
                              <span class="ms-2">{{ item.user.name }}</span>
                              <h4 v-if="item.user.deleted_at" class="text-danger fw-bold">
                                Conta excluída
                              </h4>
                            </td>
                            <td>
                              <a :href="`${$paths.users.detail}/${item.user.id}`" class="btn btn-sm btn-white lift"
                                target="_blank">
                                <span class="fe fe-arrow-up-right"></span>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </div>
                <div class="tab-pane card-body fade" id="codes" role="tabpanel" aria-labelledby="codes-tab">
                  <EmptyState v-if="!item?.codes?.length" :totalItemsRegistered="item?.codes?.length">
                    <template v-slot:noItemsRegistered>
                      <span class="fa-3x fas fa-coupon text-black-50 mb-2"></span>
                      <p>Nenhum código</p>
                    </template>
                  </EmptyState>
                  <template v-else>
                    <div v-if="item?.codes?.length" class="list-group list-group-flush">
                      <div class="list-group-item" v-for="(code, index) in item.codes" :key="index">
                        <div class="row align-items-center">
                          <div class="col">
                            <h4 class="mb-1 fw-bold text-secondary">
                              {{ code.title }}
                            </h4>
                            <p class="card-text small text-secondary">
                              <time>{{ code.code }}</time>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="tab-pane card-body fade" id="report_emails" role="tabpanel"
                  aria-labelledby="report_emails-tab">
                  <EmptyState v-if="!item?.formatted_report_emails?.length"
                    :totalItemsRegistered="item?.formatted_report_emails?.length">
                    <template v-slot:noItemsRegistered>
                      <span class="fa-3x fas fa-coupon text-black-50 mb-2"></span>
                      <p>Nenhum código</p>
                    </template>
                  </EmptyState>
                  <template v-else>
                    <div v-if="item?.formatted_report_emails?.length" class="list-group list-group-flush">
                      <div class="list-group-item" v-for="(email, index) in item.formatted_report_emails" :key="index">
                        <div class="row align-items-center">
                          <div class="col">
                            <h4 class="mb-1 fw-bold text-secondary">
                              {{ email }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- STICKY CARD -->
          <div class="col-12 col-xl-5">
            <div class="sticky-top">
              <div class="card">
                <div class="card-img-top bg-primary-soft" style="height: 8rem"></div>

                <div class="card-body text-center">
                  <div class="avatar avatar-xxl card-avatar card-avatar-top">
                    <div
                      class="avatar-img border border-5 border-card bg-white shadow justify-content-center align-items-center d-flex"
                      style="border-radius: 1rem; font-size: 2rem">
                      <span class="fas fa-building fa-2x"></span>
                    </div>
                    <!-- <img src="@/assets/img/avatar_placeholder.svg" alt="..."
                      class="avatar-img border border-5 border-card bg-white shadow" style="border-radius:1rem;" /> -->
                  </div>
                  <h1 class="card-title fw-bold mb-5">
                    {{ item?.company_name }}
                  </h1>
                  <div class="list-group list-group-flush my-n3">
                    <div class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col text-start">
                          <p class="text-muted mb-0">Desconto</p>
                        </div>
                        <div class="col-auto">
                          <p class="mb-0">
                            <span>
                              <span class="">{{ item?.discount_percentage }}%</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col text-start">
                          <p class="text-muted mb-0">Criado em</p>
                        </div>
                        <div class="col-auto">
                          <p class="mb-0">
                            <span>
                              <span class="">{{
                                item?.formatted_created_at
                                }}</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col text-start">
                          <p class="text-muted mb-0">Valor máximo<br>da sessão</p>
                        </div>
                        <div class="col-auto">
                          <p class="mb-0">
                            <span>
                              <span class="">{{ item?.max_session_cost | toCurrency }}</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col text-start">
                          <p class="text-muted mb-0">Sessões por<br>usuário</p>
                        </div>
                        <div class="col-auto text-end">
                          <p class="mb-0">
                            <span>
                              <span class="">{{ item?.appointments_per_user }}</span><br>
                              <span>{{ item?.formatted_user_limit_period }}</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col text-start">
                          <p class="text-muted mb-0">Valor máximo<br>do subsídio</p>
                        </div>
                        <div class="col-auto text-end">
                          <p class="mb-0">
                            <span>
                              <span class="">{{ item?.max_spending_amount | toCurrency }}</span><br>
                              <span>{{ item?.formatted_spending_period }}</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col text-start">
                          <p class="text-muted mb-0">Status</p>
                        </div>
                        <div class="col-auto">
                          <p class="mb-0">
                            <span>
                              <span class="">{{ item?.formatted_status }}</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <h3>Informações</h3>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Código" :value="item.code">
                  <template v-slot:labelContentEnd>
                    <CopyText :string="item.code" class="mb-1" />
                  </template>
                </TextInput>
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Desconto(%)" :value="item.percent_off" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Validade" :value="item.formatted_expiration_date" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Limite de uso por paciente" :value="item.max_redemptions_customers" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Qtd utilizada" :value="item.times_redeemed" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Data de criação" :value="item.formatted_created_at" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Criado por" :value="item.formatted_created_by" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Status" :value="item.formatted_status_obj?.name" />
              </div> -->
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import PartnerDetailController from "./PartnerDetailController";
export default PartnerDetailController;
</script>
