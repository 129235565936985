<template>
  <div id="home">
    <Navbar current="Visão geral" />
    <Sidebar />
    <Container col="col-12">
      <!-- HEADER  -->
      <Header :isSubHeader="false" title="Visão geral" :iconClass="'fa-solid fa-chart-simple text-primary'">
        <!-- :subtitle="`Informações pertencentes ao mês de ${currentMonth.capitalizeFirst()}`" -->
        <template v-slot:button>
          <div class="row">
            <div class="col">
              <SelectInput @selectChanged="dateOptionsChanged($event)" :options="dateRangeOptions"
                :model="$v.dateRangeSelected" />
            </div>
            <div class="col">
              <Datepicker format="DD/MM/YYYY" v-model="dateRange" placeholder="Selecione o período" range></Datepicker>
            </div>
          </div>
        </template>
      </Header>

      <!-- LOADING CARDS -->
      <div v-if="loadingItems" class="row">
        <div class="col-12">
          <div class="row placeholder-glow">
            <div class="col-md-4 col-12">
              <span class="placeholder placeholder-lg col-12"></span>
            </div>
            <div class="col-md-4 col-12">
              <span class="placeholder col-12"></span>
            </div>
            <div class="col-md-4 col-12">
              <span class="placeholder col-12"></span>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="row placeholder-glow">
            <div class="col-md-4 col-12">
              <span class="placeholder placeholder-lg col-12"></span>
            </div>
            <div class="col-md-4 col-12">
              <span class="placeholder col-12"></span>
            </div>
            <div class="col-md-4 col-12">
              <span class="placeholder col-12"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- CARDS -->
      <template v-else>
        <!-- <div class="row mb-4">
          <div class="col-12 col-lg-4">
            <HomeCard
              :isPrimary="true"
              iconClass="fa-solid fa-coins"
              title="Valor total das sessões"
              :qtd="info?.month_appointments_sum | toCurrency"
            />
          </div>
          <div class="col-12 col-lg-4">
            <HomeCard
              :path="$paths.appointments.imediate"
              iconClass="far fa-calendar-check"
              title="Sessões imediatas"
              :qtd="info?.month_appointments_imediate"
            />
          </div>
          <div class="col-12 col-lg-4">
            <HomeCard
              :path="$paths.appointments.scheduled"
              iconClass="far fa-calendar-check"
              title="Sessões agendadas"
              :qtd="info?.month_appointments_scheduled"
            />
          </div>
          <div class="col-12 col-lg-4">
            <HomeCard
              :path="$paths.users.list"
              iconClass="fa-solid fa-user-friends"
              title="Pacientes"
              :qtd="info?.month_users"
            />
          </div>
          <div class="col-12 col-lg-4">
            <HomeCard
              :path="$paths.users.list"
              iconClass="fa-solid fa-user-md"
              title="Psicólogos"
              :qtd="info?.month_psy"
            />
          </div>
          <div class="col-12 col-lg-4">
            <HomeCard iconClass="fa-solid fa-newspaper" title="Posts" :qtd="info?.month_posts" />
          </div>
        </div> -->

        <!-- <p class="text-muted">Informações de todo o período</p>
        <hr /> -->
        <div class="row">
          <div class="col-12 col-lg-4">
            <HomeCard :isPrimary="true" iconClass="fa-solid fa-coins" title="Valor total das sessões"
              :qtd="info?.appointments_sum | toCurrency" />
          </div>
          <div class="col-12 col-lg-4">
            <HomeCard :path="$paths.appointments.imediate" iconClass="far fa-calendar-check" title="Sessões imediatas"
              :qtd="info?.appointments_imediate" />
          </div>
          <div class="col-12 col-lg-4">
            <HomeCard :path="$paths.appointments.scheduled" iconClass="far fa-calendar-check" title="Sessões agendadas"
              :qtd="info?.appointments_scheduled" />
          </div>
          <div class="col-12 col-lg-4">
            <HomeCard :path="$paths.users.list" iconClass="fa-solid fa-user-friends" title="Pacientes"
              :qtd="info?.users" />
          </div>
          <div class="col-12 col-lg-4">
            <HomeCard :path="$paths.psychologists.list" iconClass="fa-solid fa-user-md" title="Psicólogos"
              :qtd="info?.psychologists" />
          </div>
          <!-- <div class="col-12 col-lg-4">
            <HomeCard iconClass="fa-solid fa-newspaper" title="Posts" :qtd="info?.posts" />
          </div> -->
        </div>
      </template>

      <div class="row my-5">
        <h3>Consultas dos últimos 30 dias</h3>
        <BarChart :chartData="chartData" />
      </div>
    </Container>
  </div>
</template>

<script>
import HomeController from "./HomeController";
export default HomeController;
</script>

