<template>
  <ModalComponent :title="'Alterar email'" id="add-partner-code">
    <template v-slot:body>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <TextInput label="Título" :required="true" :model="$v.form.title" :maxLength="100" type="text"
            :errorMsg="' Informe um código com no mínimo 2 caractéres'" placeholder="Título" />
        </div>
        <div class="col-12 col-md-6">
          <TextInput label="Código" :required="true" :model="$v.form.code" :maxLength="20" type="text"
            :inputStyle="'text-transform:uppercase'"
            :errorMsg="' Informe um código com no mínimo 6 caractéres somente com letras e numeros e sem espaços'"
            placeholder="PSIAPP3000" />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <FormButton label="Concluir" :disabled="$v.$invalid" class="btn w-15 btn-primary mb-3"
          @onClick="handleSubmit" />
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import { minLength } from 'vuelidate/lib/validators';
import { required } from 'vuelidate/lib/validators';

export default {
  emits: ['onAdded'],
  data() {
    return {
      form: {
        title: '',
        code: ''
      }
    }
  },
  methods: {
    handleSubmit() {
    this.form.code = this.form.code.toUpperCase()
      this.$emit('onAdded', JSON.parse(JSON.stringify(this.form)))
      this.form = {
        title: '',
        code: ''
      }
      this.$v.$reset()
    }
  },
  validations() {
    return {
      form: {
        code: {
          required,
          minLength: minLength(6),
          validChars: (value) => {
            var pattern = new RegExp('^[a-zA-Z0-9]+$');
            return pattern.test(value)
          },
        },
        title: {
          required,
          minLength: minLength(2),
        },
      }
    }
  },
};
</script>

<style></style>