import NetworkService from "@/services/NetworkService";
import moment from "moment";
import PayoutService from "./PayoutService";

export default class UserService {
  constructor() {
    this.network = new NetworkService();
    this.payoutService = new PayoutService();
  }
  SUBSCRIPTION_STATUS = {
    trialing: "trialing",
    active: "active",
  };
  list = (filters) => {
    return this.network.get(this.network.endpoints.users, filters);
  };
  create = (params) => {
    return this.network.post(this.network.endpoints.users, params);
  };
  updatePicture = (id, params) => {
    return this.network.postMultipart(
      `${this.network.endpoints.users}/${id}/edit`,
      params
    );
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.users}/${id}`, params);
  };

  reactivateUser = (user_id) => {
    return this.network.post(`${this.network.endpoints.reactivateUser}`, {user_id: user_id});
  };

  show = (id) => {
    return this.network.get(`${this.network.endpoints.users}/${id}`);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.users}/${id}`);
  };
  settings = (key) => {
    return this.network.get(`api/${key}`);
  };
  updateSettings = (key, value) => {
    return this.network.put(`api/${key}`, { terms_of_use: value });
  };
  login = async (params) => {
    let res = await this.network.post(this.network.endpoints.login, params);
    if (["admin", "specialist"].includes(res.data.type)) {
      return res;
    } else {
      throw { status: false, message: "Credenciais inválidas" };
    }
  };
  logout = () => {
    return this.network.put(`${this.network.endpoints.users}/logout`);
  };
  listStatistics = (params) => {
    return this.network.get(this.network.endpoints.summary, params);
  };
  listAppointmentGraphics = (params) => {
    return this.network.get(this.network.endpoints.appointmentGraphics, params);
  };
  createWorkingHours(params) {
    return this.network.post(this.network.endpoints.workingHours, params);
  }
  listCrashes = (params) => {
    return this.network.get(this.network.endpoints.appCrashes, params);
  };
  showCrash = (id) => {
    return this.network.get(`${this.network.endpoints.appCrashes}/${id}`);
  };
  exportUsers(params) {
    return this.network.post(this.network.endpoints.exportUsers, params);
  }
  givePremium(user_id) {
    return this.network.post(this.network.endpoints.givePremium, { user_id });
  }
  removePremium(user_id) {
    return this.network.post(this.network.endpoints.removePremium, { user_id });
  }
  preparePsi(item) {
    //qdo precisar formatar o psi, usa essa funcao
    try {
      item.formatted_birthdate = moment(item.birth_date).format("DD/MM/YYYY");
      item.formatted_created_at = moment(item.created_at).format("DD/MM/YYYY [às] HH:mm");

      item.formatted_gender = item.gender == "male" ? "Masculino" : "Feminino";
      item.formatted_price = item.appointment_price
        ? item.appointment_price.toCurrency()
        : "";
      item.isPremium =
        item.subscription &&
        (item.subscription?.status == this.SUBSCRIPTION_STATUS.trialing ||
          item.subscription?.status == this.SUBSCRIPTION_STATUS.active);
      if (item.payouts?.length) {
        item.payouts.map((elem) => {
          elem.formatted_payout = this.payoutService.preparePayout(elem);
        });
      }
      if (item.latest_ratings?.length) {
        item.latest_ratings = item.latest_ratings.filter(
          (elem) => elem.user != null
        );
      }
      item.callProviderLabel =
        item.call_provider == "agora" ? "Agora" : "Whereby";
    } catch (e) {
      console.log(e);
    } finally {
      return item;
    }
  }
  prepareUser(item) {
    //qdo precisar formatar o psi, usa essa funcao
    try {
      item.formatted_created_at = moment(item.created_at).format("DD/MM/YYYY [às] HH:mm");
    } catch (e) {
      console.log(e);
    } finally {
      return item;
    }
  }
}
