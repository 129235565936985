<template>
  <div id="add-coupon">
    <Navbar current="Adicionar video" :link="$paths.coupons.list" :linkName="'Videos personalizados'" />
    <Sidebar />
    <Container>
      <Header title="Adicionar video" />
      <div class="card">
        <div class="card-body">
          <h3>Informações</h3>
          <div class="row">
            <div class="col-12 col-md-12">
              <TextInput label="Descrição" :required="true" :model="$v.form.description" :maxLength="50" type="text"
                :errorMsg="'Informe uma descrição com no mínimo 6 caractéres'" placeholder="Descrição do video" />
            </div>
            <div class="col-12 col-md-12">
              <SelectInput label="Aplicativo" :options="$v.targetSelectOptions" :model="$v.form.target" />
            </div>
            <div class="col-12 col-md-12">
              <label class="form-label text-muted">Thumbnail do video<i class="text-danger">*</i></label>
              <ImageCropper modalId="video-thumb-image" :accept="'.jpeg,.png,.jpg'" :aspectRatio="1 / 1"
                v-on:cropEnd="cropEnd($event)" inputId="video-thumb-input" />
            </div>
            <div class="col-12 col-md-12">
              <label class="form-label text-muted">Video<i class="text-danger">*</i></label>
              <VideoUploader accept=".mp4" v-on:onUploaded="onVideoUploaded($event)" inputId="video-input" />
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton label="Adicionar" :disabled="$v.$invalid" :loading="submitting" @onClick="submit" />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import VideoAddController from "./VideoAddController";
export default VideoAddController;
</script>
<style scoped></style>
