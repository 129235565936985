<template>
  <div id="chats">
    <Navbar current="Sessões" />
    <Sidebar />
    <Container col="col-12">
      <Header
        :headerClass="'mb-0'"
        :headerBodyClass="'pb-0 border-0'"
        :isSubHeader="false"
        title="Sessões"
        subtitle="Registro das sessões"
        :iconClass="'far fa-calendar-check text-primary'"
        :totalCount="tabs.map((y) => y.totalCount).reduce((a, b) => a + b)"
      >
        <template slot="button">
          <div class="row">
            <div class="col">
              <SelectInput
                @selectChanged="dateOptionsChanged($event)"
                :options="dateRangeOptions"
                :model="$v.dateRangeSelected"
              />
            </div>
            <div class="col">
              <Datepicker
                format="DD/MM/YYYY"
                v-model="dateRange"
                placeholder="Selecione o período"
                range
              ></Datepicker>
            </div>
          </div>
        </template>
      </Header>
      <ul class="nav nav-tabs mb-4">
        <li
          tag="li"
          class="nav-item"
          v-for="(tab, index) in tabs"
          v-bind:key="'tabp-' + index"
          v-on:click="selectedTab = tab.id"
        >
          <a
            class="nav-link cursor-pointer"
            :class="{ active: selectedTab == tab.id }"
            @click.prevent="handleChangeTab(tab)"
          >
            {{ tab.name }}
            <span class="badge bg-primary-soft" style="font-size: 0.8rem">{{
              tab.totalCount || 0
            }}</span>
          </a>
          <!-- <router-link
            tag="a"
            :to="`${$paths.appointments.list}/${tab.id}`"
            class="nav-link"
            :class="{ active: selectedTab == tab.id }"
          >
            {{ tab.name }}
            <span class="badge bg-primary-soft" style="font-size: 0.8rem">{{
              tab.totalCount || 0
            }}</span>
          </router-link> -->
        </li>
      </ul>
      <div class="tab-content" id="myTabContent2">
        <div
          class="tab-pane fade"
          id="appointments-scheduled"
          role="tabpanel"
          aria-labelledby="appointments-scheduled-tab"
          tabindex="0"
          :class="{ 'show active': selectedTab == 'agendadas' }"
        >
          <AppointmentList
            v-on:totalItemsChanged="totalChanged($event, 'agendadas')"
            :propFilters="{ type: 'scheduled' }"
            :dateRange="dateRange"
          />
        </div>
        <!-- <div
          class="tab-pane fade"
          id="appointments-imediate"
          role="tabpanel"
          aria-labelledby="appointments-imediate-tab"
          tabindex="0"
          :class="{ 'show active': selectedTab == 'imediatas' }"
        >
          <AppointmentList
            v-on:totalItemsChanged="totalChanged($event, 'imediatas')"
            :propFilters="{ type: 'imediate' }"
            :dateRange="dateRange"
          />
        </div> -->

        <div
          class="tab-pane fade"
          id="appointments-ongoing"
          role="tabpanel"
          aria-labelledby="appointments-ongoing-tab"
          tabindex="0"
          :class="{ 'show active': selectedTab == 'andamento' }"
        >
          <AppointmentList
            v-on:totalItemsChanged="totalChanged($event, 'andamento')"
            :propFilters="{ status: ['em andamento'] }"
            :dateRange="dateRange"
            :showStatusFilter="false"
          />
        </div>

        <div
        class="tab-pane fade"
        id="appointments-online"
        role="tabpanel"
        aria-labelledby="appointments-online-tab"
        tabindex="0"
        :class="{ 'show active': selectedTab == 'online' }"
      >
        <AppointmentList
          v-on:totalItemsChanged="totalChanged($event, 'online')"
          :propFilters="{ online: true }"
          :dateRange="dateRange"
          :showStatusFilter="false"
        />
      </div>

      </div>
    </Container>
  </div>
</template>
<script>
import AppointmentsController from "./AppointmentsController";
export default AppointmentsController;
</script>
