<template>
  <div class="pb-4">
    <!-- <input type="text" v-on:keyup.enter="handleAddItem($event)" /> -->
    <TextInput
      :label="label"
      :model="model"
      :maxLength="200"
      :placeholder="placeholder"
      :errorMsg="errorMsg"
      @keyupEnter="handleAddItem($event)"
    >
    <template v-slot:labelContentEnd>
        <p class="mb-2 text-caption text-warning">
          <i class="fa-solid fa-triangle-exclamation"></i>
          {{ msg }}
        </p>
      </template>
    </TextInput>
    <div class="list-group list-group-flush">
      <div v-for="(item, index) in items" :key="index" class="list-group-item py-1">
        <div class="row align-items-center">
          <div class="col-auto px-0">
            <button class="btn btn-link" @click="handleRemoveItem(index)">
              <span class="fas fa-trash-alt text-danger"></span>
            </button>
          </div>
          <div class="col ms-n2">
            <div class="card-text small text-body-secondary">
              <h4 class="mb-0">
                {{ item }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    items: Array,
    model: {
      required: false,
      default: () => { },
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    msg: {
      type: String,
      default: "Informe o item e pressione enter para adicioná-lo na lista",
    },
    errorMsg: {
      type: String,
      default: "",
    },
  },
  emits: ["handleAddItem", "handleRemoveItem"],
  methods: {
    handleAddItem(event) {
      if (event.target.value.trim() === "") {
        return;
      }
      if (this.model.$error) {
        return;
      }
      this.$emit("handleAddItem", event.target.value);
    },
    handleRemoveItem(index) {
      this.$emit("handleRemoveItem", index);
    },
  },
});
</script>
