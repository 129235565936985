import { Modal } from "bootstrap";
import ListMixinController from "../../mixins/ListMixinController";
export default {
  name: "Users",
  mixins: [ListMixinController],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      columns: [
        {
          name: "Empresa",
          sortName: null,
          order: null,
        },
        // {
        //   name: "Codigo de ativação",
        //   sortName: null,
        //   order: null,
        // },
        {
          name: "Funcionários ativos",
          sortName: null,
          order: null,
        },
        {
          name: "Sessões realizadas",
          sortName: null,
          order: null,
        },
        // {
        //   name: "Status",
        //   sortName: null,
        //   order: null,
        // },
        {
          name: "Data de criação",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$partnerService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    prepareResponse(data) {
      return data.map(elem => (this.$partnerService.preparePartner(elem)))
    },
    handleDetail(id) {
      this.$router.push({ path: `${this.$paths.partners.detail}/${id}` });
    },
  },
};
