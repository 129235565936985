import { Modal } from "bootstrap";
import AppointmentList from "../../Appointments/AppointmentList/AppointmentList.vue";
import UserDetail from "../../Users/UserDetail/UserDetail.vue";

import { changeActive, changeEmail, reactivateUser } from "./userFunctions";
import { email, required } from "vuelidate/lib/validators";
import ListMixinController from "@/mixins/ListMixinController";

export default {
  mixins: [ListMixinController],
  mounted() {
    this.changeEmailModal = new Modal(
      document.getElementById("changeEmailModal"),
      {
        backdrop: "static",
        keyboard: false,
      }
    );
    this.freeAppointmentModal = new Modal(
      document.getElementById("freeAppointmentModal"),
      {
        backdrop: "static",
        keyboard: false,
      }
    );
    this.loading = true;
    this.getUser();
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      loading: false,
      changeEmailModal: null,
      freeAppointmentModal: null,
      loadingChat: false,
      item: {},
      id: this.$route.params.id,
      loadingBlock: false,
      submitting: false,
      errorMessage: "",
      form: {
        email: "",
      },
      freeAppointmentForm: {
        user_id: Number(this.$route.params.id),
        psychologist_id: null,
        date: "",
      },
      psychologists: [],
    };
  },
  validations: {
    freeAppointmentForm: {
      user_id: { required },
      psychologist_id: { required },
      date: { required },
    },
    form: {
      email: {
        required,
        email
      },
    },
  },
  methods: {
    async getUser() {
      try {
        const res = await this.$userService.show(this.id);
        let data = this.$userService.prepareUser(res.data);
        data.appointments = data.appointments.map((elem) =>
          this.$appointmentsService.prepareAppointment(elem)
        );
        data.stripe_link = data.customer_id ? process.env.VUE_APP_STRIPE_BASE_URL + "customers/" + data.customer_id : null

        this.$set(this, "item", data);
        this.$set(this.form, "email", data.email);
        console.log(data.email)

        
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    changeEmailClicked() {
      this.changeEmailModal.toggle();
    },
    async freeAppointment() {
      this.loadingItems = true;
      this.freeAppointmentModal.toggle();
      this.getData();
    },
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        type: "psychologist",
      });
    },
    prepareResponse(data) {
      return data.map((elem) => {
        return this.$userService.preparePsi(elem);
      });
    },
    handleChangeEmail() {
      this.submitting = true;
      changeEmail({ id: this.item.id, email: this.form.email }, (res) => {
        console.log(res);
        this.$eventBus.$emit("showToast", {
          title: "Email alterado",
          msg: `O email foi alterado com sucesso`,
        });
        this.changeEmailModal.toggle();
        this.getUser();
        this.submitting = false;
      });
    },
    async handleFreeAppointment() {
      try {
        this.submitting = true;
        const params = {
          ...this.freeAppointmentForm,
          date: this.freeAppointmentForm.date.replace("T", " "),
        };
        const res = await this.$appointmentsService.freeAppointment(params);
        console.log(res);

        this.$eventBus.$emit("showToast", {
          title: "Sessão agendada",
          msg: `Sessão agendada com sucesso`,
        });
        this.freeAppointmentModal.toggle();
        this.$router.push({
          path: `${this.$paths.appointments.detail}/${res.data.id}`,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },
    selectPsi(psi) {
      if (psi?.deleted_at) return;
      const psiId =
        this.freeAppointmentForm.psychologist_id == psi?.id ? null : psi?.id;
      this.$set(this.freeAppointmentForm, "psychologist_id", psiId);
    },
    changeActive() {
      changeActive(this.item, () => this.getUser());
    },
    reactivateUser() {
      reactivateUser(this.item, () => this.getUser());
    },
  },
  components: {
    AppointmentList,
    UserDetail
  },
};
