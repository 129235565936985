import NetworkService from "@/services/NetworkService.js";
import store from "@/store";
import moment from "moment";
import PayoutService from "./PayoutService";

export default class AppointmentsService {
  constructor() {
    this.network = new NetworkService();
    this.payoutService = new PayoutService();
  }

  static STATUS = {
    AGUARDANDO: "aguardando",
    AGENDADO: "agendado",
    CONCLUIDO: "concluido",
    CANCELADO: "cancelado",
    RECUSADO: "recusado",
    ANDAMENTO: "em andamento",
  }
  static TYPES = {
    IMEDIATE: 'imediate',
    SCHEDULED: 'scheduled'
  }

  statusList = [
    // { id: '', title: 'Todos', checked: false },
    { id: AppointmentsService.STATUS.AGUARDANDO, title: "Aguardando" },
    { id: AppointmentsService.STATUS.CANCELADO, title: "Cancelada" },
    { id: AppointmentsService.STATUS.RECUSADO, title: "Recusado" },
    { id: AppointmentsService.STATUS.ANDAMENTO, title: "Em andamento" },
    { id: AppointmentsService.STATUS.CONCLUIDO, title: "Concluída" },
    { id: AppointmentsService.STATUS.AGENDADO, title: "Agendado" },
  ];
  list = (params) => {
    return this.network.get(this.network.endpoints.appointments, { ...params });
  };
  getAppointmentPaymentStatus = ({
    pg_status,
    charge_status,
    refund_status,
    reason_for_cancellation,
  }) => {
    if (!pg_status)
      return {
        title: "Sem status",
        description: "",
        textClass: "text-success",
        iconClass: "fa-solid fa-circle-xmark text-muted",
      };

    if (charge_status == "failed") {
      // erro no pgto ( cartao recusado, etc )
      return {
        title: "Erro no pagamento",
        description: `Ocorreu um erro ao processar o pagamento. ${reason_for_cancellation ||
          " (Cartão recusado, fundos insuficientes, etc)"
          }`,
        textClass: "text-success",
        iconClass: "fa-solid fa-circle-xmark text-danger",
      };
    }
    if (refund_status == "succeeded" && pg_status == "canceled") {
      // fez/registrou a reserva e cancelou a mesma
      return {
        title: "Reserva cancelada",
        description:
          "A reserva de pagamento foi registrada, mas foi cancelada. Não houve a cobrança de taxas",
        textClass: "text-success",
        iconClass: "fa-solid fa-circle-xmark text-muted",
      };
    }
    if (refund_status == "succeeded" && pg_status == "succeeded") {
      // consulta foi paga e reembolsada
      return {
        title: "Reembolsado",
        description:
          "O Pagamento foi efetuado, mas foi reembolsado. Houve a cobrança de taxas de transação por parte do stripe",
        textClass: "text-success",
        iconClass: "fa-solid fa-circle-left text-warning",
      };
    }
    /*
    requires_payment_method
    requires_confirmation // nao usa
    requires_action // nao usa
    processing
    requires_capture
    canceled
    or succeeded
    */
    switch (pg_status) {
      case "succeeded":
        return {
          title: "Pago",
          description: "O Pagamento foi efetuado com sucesso",
          textClass: "text-success",
          iconClass: "fa-solid fa-circle-check text-success",
        };
      case "requires_capture":
        return {
          title: "Pendente (em reserva)",
          description:
            "A reserva foi registrada e está aguardando a captura do pagamento",
          textClass: "text-success",
          iconClass: "fa-solid fa-circle-xmark ms-1",
        };
      case "requires_payment_method":
        return {
          title: "Pendente",
          description: "Aguardando pagamento",
          textClass: "text-success",
          iconClass: "fa-solid fa-circle-xmark ms-1",
        };
      case "canceled":
        return {
          title: "Cancelado",
          description: "O pagamento foi cancelado",
          textClass: "text-success",
          iconClass: "fa-solid fa-circle-xmark text-muted",
        };
      case "processing":
        return {
          title: "Processando",
          description: "O Pagamento está sendo processado",
          textClass: "text-success",
          iconClass: "fa-solid fa-circle-xmark ms-1",
        };
      default:
        return {
          title: "Sem status",
          description: "",
          textClass: "text-success",
          iconClass: "fa-solid fa-circle-xmark ms-1",
        };
    }
  };

  getAppointmentStatus = (status) => {
    const defaultObject = {
      title: "",
    };
    switch (status) {
      case "aguardando":
        return {
          title: "Aguardando",
          badgeClass: "badge fw-bold bg-light",
          iconClass: "fa-solid fa-clock text-info",
        };
        break;
      case "cancelado":
        return {
          title: "Cancelada",
          badgeClass: "badge bg-danger-soft",
          iconClass: "fa-solid fa-times-circle text-muted",
        };
        break;
      case "recusado":
        return {
          title: "Recusado",
          badgeClass: "badge bg-danger-soft",
          iconClass: "fa-solid fa-hand text-danger",
        };
        break;
      case "em andamento":
        return {
          title: "Em andamento",
          badgeClass: "badge bg-primary-soft",
          iconClass: "",
        };
        break;
      case "concluido":
        return {
          title: "Concluída",
          badgeClass: "badge fw-bold bg-success-soft",
          iconClass: "fa-solid fa-circle-check text-success",
        };
      case "agendado":
        return {
          title: "Agendado",
          badgeClass: "badge bg-dark-soft",
          iconClass: "fa-solid fa-clock text-info",
        };
        break;
      default:
        return defaultObject;
    }
  };

  listReports = (params) => {
    return this.network.get(this.network.endpoints.reports, { ...params });
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.appointments}/${id}`);
  };
  update = (id, params) => {
    return this.network.put(
      `${this.network.endpoints.appointments}/${id}`,
      params
    );
  };
  cancelRecurrency = (id) => {
    return this.network.delete(
      `${this.network.endpoints.appointmentRecurrencies}/${id}`
    );
  };
  refund = (params) => {
    return this.network.post(this.network.endpoints.refund, params);
  };
  listRatings = (params) => {
    return this.network.get(`${this.network.endpoints.ratingsReport}`, {
      review_requested: true,
    });
  };
  prepareAppointment(data) {
    const session = store.state.userSession;
    try {
      data.statusObject = this.getAppointmentStatus(data.status);
      data.paymentStatus = this.getAppointmentPaymentStatus(data);
      data.formatted_date = moment(data.date).format("DD/MM/YYYY[ - ]HH:mm");
      data.formatted_type = data.type == "scheduled" ? "Agendada" : "Imediata";
      if (data.ratings) {
        data.psi_rating = data.ratings.find(
          (r) => r.rating_by == "psychologist"
        );
        data.user_rating = data.ratings.find((r) => r.rating_by == "user");
      }
      data.refundAllowed =
        data.pg_status === "succeeded" &&
        data?.refund_status !== "succeeded" &&
        [
          "bruno-admin@phurshell.com",
          "omartins@phurshell.com",
          "carlosmuzzi1968@gmail.com",
        ].includes(session?.email);
      if (
        data.status == "concluido" &&
        data.payout &&
        data.payout.main_payout_info
      ) {
        data.formatted_payout = this.payoutService.preparePayout(
          data.payout.main_payout_info
        );

        data.formatted_payout_transaction = this.payoutService.preparePayoutTransaction(
          data.payout
        );

        
      }
      data.callProviderLabel =
        data.call_provider == "agora" ? "Agora" : "Whereby";

      data.stripe_link = data.pg_transaction_id ? process.env.VUE_APP_STRIPE_BASE_URL + "payments/" + data.pg_transaction_id : null
      // console.log(data.pg_transaction_id)
      // console.log(data.stripe_link)


    } catch (e) {
      console.log(e);
    } finally {
      return data;
    }
  }
  freeAppointment = (params) => {
    return this.network.post(this.network.endpoints.freeAppointment, params);
  };
}
