<template>
  <div id="appointment_info">
    <div class="row">
      <div class="col-12">
        <h2 class="fw-bold mb-4">
          Fluxo de agendamento
        </h2>
      </div>

      <!-- SCHEDULING FLOW -->
      <div class="col-12">
        <div class="card mb-0">
          <div class="card-body">
            <div class="list-group list-group-flush my-n3">


              <!-- Patient requested an appointment. -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-regular fa-calendar-plus"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Paciente solicitou a sessão
                    </h4>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.admin_milestones?.created" class="fw-bold">
                      <i class="fa-solid fa-circle-check text-success"></i>
                      Concluído
                    </span>
                    <span v-else class="">
                      <i class="fa-solid fa-clock text-muted"></i>
                      Aguardando
                    </span>
                  </div>
                </div>
              </div>

              <!-- Psychologist accept the appointment. -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-user-check"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Psicólogo aceitou a solicitação
                    </h4>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.admin_milestones?.accepted" class="fw-bold">
                      <i class="fa-solid fa-circle-check text-success"></i>
                      Concluído
                    </span>
                    <span v-else class="">
                      <i class="fa-solid fa-clock text-muted"></i>
                      Aguardando
                    </span>
                  </div>
                </div>
              </div>

              <!-- Room available -->
              <!-- <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-door-closed"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Sala disponível
                    </h4>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.canEnter" class="fw-bold">
                      <i class="fa-solid fa-door-open text-success"></i>
                      Disponível
                    </span>
                    <span v-else class="">
                      <i class="fa-solid fa-door-closed text-muted"></i>
                      Indisponível
                    </span>
                  </div>
                </div>
              </div> -->

              <!-- Appointment starts -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-play"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Sessão é iniciada.
                    </h4>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.admin_milestones?.started" class="fw-bold">
                      <i class="fa-solid fa-circle-check text-success"></i>
                      Concluído
                    </span>
                    <span v-else class="">
                      <i class="fa-solid fa-clock text-muted"></i>
                      Aguardando
                    </span>
                  </div>
                </div>
              </div>

              <!-- Psychologist get in the room. -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-user-doctor"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Psicólogo entrou na sala
                    </h4>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.admin_milestones?.psy_joined" class="fw-bold">
                      <i class="fa-solid fa-circle-check text-success"></i>
                      Concluído
                    </span>
                    <span v-else class="">
                      <i class="fa-solid fa-clock text-muted"></i>
                      Aguardando
                    </span>
                  </div>
                </div>
              </div>

              <!-- Patient get in the room. -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-user"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Paciente entrou na sala
                    </h4>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.admin_milestones?.patient_joined" class="fw-bold">
                      <i class="fa-solid fa-circle-check text-success"></i>
                      Concluído
                    </span>
                    <span v-else class="">
                      <i class="fa-solid fa-clock text-muted"></i>
                      Aguardando
                    </span>
                  </div>
                </div>
              </div>

              <!-- 30 minutes more. -->
              <!-- <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-clock"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Mais de 30 minutos
                    </h4>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.admin_milestones?.min_duration" class="fw-bold">
                      <i class="fa-solid fa-circle-check text-success"></i>
                      Concluído
                    </span>
                    <span v-else class="">
                      <i class="fa-solid fa-clock text-muted"></i>
                      Aguardando
                    </span>
                  </div>
                </div>
              </div> -->

              <!-- Session is concluded. -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-circle-check"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Sessão encerrada
                    </h4>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.admin_milestones?.concluded" class="fw-bold">
                      <i class="fa-solid fa-circle-check text-success"></i>
                      Concluído
                    </span>
                    <span v-else class="">
                      <i class="fa-solid fa-clock text-muted"></i>
                      Aguardando
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-12">
        <hr class="my-4">
        <h2 class="fw-bold mb-4">
          Outras informações
        </h2>
      </div> -->

      <!-- <div class="col-12">
        <div class="list-group">
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col-5 ms-n2">
                <h4 class="mb-1">
                  Sala disponível para entrar
                </h4>
              </div>
              <div class="col-auto ms-auto me-n3">
                <span class="fw-bold me-2">
                  {{ item.canEnter ? 'Sim' : 'Não' }}
                </span>
              </div>
            </div>
          </div>
        </div>

      </div> -->

      <div class="col-12">
        <hr class="my-4">
        <h2 class="fw-bold mb-4">
          Repasse  <small>#{{ item.payout?.id }}</small>
        </h2> 
      </div>
      <div class="col-12">
        <div class="list-group list-group-flush">
          <template v-if="item.formatted_payout">
            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="mb-0">
                    Data do repasse
                  </h5>
                </div>
                <div class="col-auto">
                  <time class="small text-body-secondary">
                    <strong> {{ item.formatted_payout.date }}</strong>
                  </time>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="mb-0">
                    Disponível em
                  </h5>
                </div>
                <div class="col-auto">
                  <time class="small text-body-secondary">
                    <strong> {{ item.formatted_payout_transaction?.available_on }}</strong>
                  </time>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="mb-0">
                    Status
                  </h5>
                </div>
                <div class="col-auto">
                  <time class="small text-body-secondary">

                    <strong> {{ item.formatted_payout.statusObject.title }}</strong>

                   
                  </time>
                </div>
              </div>
            </div>

            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="mb-0">
                    Valor referente sessão
                  </h5>
                </div>
                <div class="col-auto">
                  <time class="small text-body-secondary">
                    <strong> {{ item.formatted_payout_transaction?.net }}</strong> 
                  </time>
                </div>
              </div>
            </div>

            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="mb-0">
                    Valor total do repasse
                  </h5>
                </div>
                <div class="col-auto">
                  <time class="small text-body-secondary">
                    <strong>{{ item.formatted_payout?.amount }}</strong> 
                  </time>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="mb-0">
                    Repasse no Stripe
                  </h5>
                </div>
                <div class="col-auto">
                  <time class="small text-body-secondary">

                    <a :href="`${item.stripe_payout_link }`" target="_blank"> Stripe
                      <i class="fa-brands fa-cc-stripe fa-xl text-dark" style="margin-left: 5px"></i>
                    </a>

                  </time>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="mb-0 text-center text-muted">
                    Não houve repasse para esta sessão ainda.
                  </h4>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="col-12">
        <hr class="my-4">
        <h2 class="fw-bold mb-4">
          Auxiliar
        </h2>
      </div>
      <div class="col-12">
        <div class="list-group">
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col-5 ms-n2">
                <h4 class="mb-1">
                  ID da sessão
                </h4>
              </div>
              <div class="col-auto ms-auto me-n3">
                <span class="lead fw-bold me-2">
                  {{ item.id }}
                </span>
                <button class="btn btn-sm btn-white lift">
                  <i class="fa-regular fa-copy"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="list-group-item" v-if="item.call_provider == 'agora'">
            <div class="row align-items-center">
              <div class="col-5 ms-n2">
                <h4 class="mb-1">
                  Agora channel
                </h4>
              </div>
              <div class="col-auto ms-auto me-n3">
                <span class="lead fw-bold me-2">
                  {{ item.agora_channel }}
                </span>
                <!-- <button class="btn btn-sm btn-white lift">
                  <i class="fa-regular fa-copy"></i>
                </button> -->
              </div>
            </div>
          </div>

          <div class="list-group-item" v-if="item.call_provider == 'whereby'">
            <div class="row align-items-center">
              <div class="col-5 ms-n2">
                <h4 class="mb-1">
                  Whereby Link
                </h4>
              </div>
              <div class="col-7 ms-auto me-n3">
                <span class="fw-bold me-2" v-if="item.call_link">
                  {{ item.call_link }}
                </span>
                <span class="fw-bold me-2 text-right" v-else>Ainda não disponível</span>
              </div>
            </div>
          </div>


        </div>

      </div>



    </div>
  </div>
</template>
<script>
export default {
  name: 'InfoComponent',
  props: {
    item: Object,
    default: {},
  },
  created() {
    const url_mid = process.env.VUE_APP_ENV == 'prod' ? "payouts" : "test/payouts"

    this.item.stripe_payout_link = `https://dashboard.stripe.com/${this.item.psychologist?.stripe_id}/${url_mid}/${this.item.payout?.main_payout_info?.stripe_payout_id}`;

  },
  mounted() {
    console.log('stripe payout link:', this.item.stripe_payout_link);
    console.log('InfoComponent item:', this.item);
  }
}
</script>
