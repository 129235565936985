import AddPartnerCode from '@/components/AddPartnerCode.vue';
import { toggleModal } from '@/components/ModalComponent.vue';
import PartnerService from '@/services/PartnerService';
import { email, maxValue, minLength, minValue, required } from 'vuelidate/lib/validators';
import FormMixinController from '@/mixins/FormMixinController';
export default {
  mixins: [FormMixinController],
  name: 'AddCoupon',
  mounted() {
    console.log(this.isAdding)
    this.redirectPath = this.isAdding ? this.$paths.partners.detail : null
  },
  data() {
    return {
      toggleModal: toggleModal,
      periodSelect: PartnerService.periodSelec,
      form: {
        hasUserLimit: false,
        hasAppointmentValueLimit: false,
        codes: [],
        discount: '',
        company_name: '',

        appointments_per_user: '',
        max_spending_amount: '',
        spending_period: '',
        max_session_cost: '',
        user_limit_period: '',

        report_emails: [],
        email: '',
      },
    };
  },
  computed: {
    title() {
      return this.isAdding ? 'Adicionar empresa' : 'Editar empresa'
    }
  },
  components: {
    AddPartnerCode
  },
  validations() {
    return {
      form: {
        hasUserLimit: '',
        report_emails: '',
        email: {
          email
        },
        hasAppointmentValueLimit: '',
        codes: {
          required,
        },
        company_name: {
          required,
          minLength: minLength(2),
        },
        max_spending_amount: this.form.hasAppointmentValueLimit ? {
          required
        } : {},
        max_session_cost: this.form.hasAppointmentValueLimit ? {
          required
        } : {},
        spending_period: {
          // required,
        },
        appointments_per_user: this.form.hasUserLimit ? {
          required,
          minValue: minValue(1),
        } : {},
        user_limit_period: this.form.hasUserLimit ? {
          required
        } : {},
        discount: {
          required,
          maxValue: maxValue(30),
          minValue: minValue(1),
        },
      }
    }
  },
  methods: {
    fetchPromise() {
      return this.$partnerService.show(this.id)
    },
    setupForm(data) {
      if (data.appointments_per_user && data.user_limit_period) {
        this.form.hasUserLimit = true
        this.form.appointments_per_user = data.appointments_per_user
        this.form.user_limit_period = data.user_limit_period
      }
      if (data.max_spending_amount && data.spending_period) {
        this.form.hasAppointmentValueLimit = true
        this.form.max_spending_amount = data.max_spending_amount
        this.form.spending_period = data.spending_period
      }
      this.form.company_name = data.company_name
      this.form.max_session_cost = data.max_session_cost
      this.form.discount = data.discount_percentage
      this.form.codes = data.codes.map(elem => ({ id: elem.id, title: elem.title, code: elem.code }))
      this.form.report_emails = data.report_emails.split('|')
      return data
    },
    handleSubmit() {
      const values = JSON.parse(JSON.stringify(this.form))
      let params = {
        discount_percentage: values.discount,
        company_name: values.company_name,
        max_session_cost: values.max_session_cost.currencyToNumber(),
        codes: values.codes
      }
      if (this.form.hasUserLimit) {
        params.appointments_per_user = values.appointments_per_user
        params.user_limit_period = values.user_limit_period
      }
      if (this.form.hasAppointmentValueLimit) {
        params.max_spending_amount = values.max_spending_amount.currencyToNumber()
        params.spending_period = values.spending_period
      }
      if (this.form.report_emails.length) {
        params.report_emails = this.form.report_emails.join('|')
      }
      if (this.isAdding) {
        return this.$partnerService.create(params)
      } else {
        return this.$partnerService.update(this.id, params)
        // return new Promise((resolve, reject) => { })
      }
    },
    addEmail(email) {
      console.log(email)
      if (!this.form.report_emails.includes(email)) {
        this.form.report_emails.push(email)
      }
      this.form.email = ''
    },
    removeEmail(index) {
      this.form.report_emails.splice(index, 1)
    },
    codeAdded(response) {
      toggleModal('add-partner-code')
      this.form.codes.push(response)
    },
    removeCode(index) {
      this.form.codes.splice(index, 1)
    }
  }
};