<template>
  <div id="users">
    <Navbar current="Empresas parceiras" />
    <Sidebar />
    <Container col="col-12">
      <Header title="Empresas parceiras" :isSubHeader="false" subtitle="Empresas participantes da parceria"
        :iconClass="'fa-solid fa-handshake text-primary'" :totalCount="totalItemsRegistered">
        <template v-slot:button>
          <a class="btn btn-primary" :href="$paths.partners.add">
            <i class="fa-solid fa-plus-circle me-2"></i>
            Adicionar
          </a>
        </template>
      </Header>
      <div class="card">
        <SearchBar @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
            <template v-slot:noItemsRegistered>
              <span class="fa-3x fa-solid fa-handshake text-black-50 mb-2"></span>
              <p>Nenhum usuário criado</p>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table ref="myTable" class="table table-sm table-nowrap card-table table-hover cursor-pointer">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <tr v-for="(item, index) in items" v-bind:key="'tr-' + index" @click="handleDetail(item.id, index)">
                  <td>
                    {{ item.company_name }}
                  </td>
                  <!-- <td>
                    {{ item.code }}
                  </td> -->
                  <td>{{ item.patients_count }}</td>
                  <td>{{ item.appointments_count }}</td>
                  <!-- <td>{{ item.formatted_status }}</td> -->
                  <td class="text-end"> <time>{{ item.formatted_created_at }}</time> </td>
                </tr>
                <!-- </router-link> -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import PartnersController from "./PartnersController";
export default PartnersController;
</script>
