import NetworkService from "@/services/NetworkService";
import moment from "moment";
import PayoutService from "./PayoutService";

export default class PartnerService {
  constructor() {
    this.network = new NetworkService();
    this.payoutService = new PayoutService();
  }
  SUBSCRIPTION_STATUS = {
    trialing: "trialing",
    active: "active",
  };
  static PERIODS = {
    monthly: "monthly",
    anually: "yearly",
  };

  static periodSelec = [
    {
      id: PartnerService.PERIODS.monthly,
      title: 'Mensal'
    },
    {
      id: PartnerService.PERIODS.anually,
      title: 'Anual'
    }
  ]
  list = (filters) => {
    return this.network.get(this.network.endpoints.partnerships, filters);
  };
  create = (params) => {
    return this.network.post(this.network.endpoints.partnerships, params);
  };
  getPartnersAppointments = async (id) => {
    try {
      const res = await this.network.get(`${this.network.endpoints.appointments}`, { partnership_id: id, });
      return res.data
    } catch (e) {
      console.log(e);
      return []
    } finally {
    }
  };
  getPartnerPatients = async (id) => {
    try {
      const res = await this.network.get(`${this.network.endpoints.partnerPatients}`, { partnership_id: id });
      return res.data
    } catch (e) {
      console.log(e);
      return []
    } finally {
    }
  };

  show = async (id) => {
    try {
      let partner = await this.network.get(
        `${this.network.endpoints.partnerships}/${id}`
      );
      partner.data = this.preparePartner(partner.data);
      const appointments = await this.getPartnersAppointments(id);
      const partnerPatients = await this.getPartnerPatients(id);
      return {
        status: true,
        data: {
          ...partner.data,
          appointments: appointments,
          patients: partnerPatients.data,
        },
      };
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.partnerships}/${id}`, params);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.partnerships}/${id}`);
  };
  preparePartner(item) {
    try {
      item.formatted_created_at = moment(item.created_at).format("DD/MM/YYYY");
      item.formatted_status = item.status ? "Ativo" : "Inativo";
      const formatted_spending_period = item.spending_period ? PartnerService.periodSelec.find(period => period.id === item.spending_period) : null
      const formatted_user_limit_period = item.user_limit_period ? PartnerService.periodSelec.find(period => period.id === item.user_limit_period) : null
      item.formatted_spending_period = formatted_spending_period?.title || ''
      item.formatted_user_limit_period = formatted_user_limit_period?.title || ''
      item.formatted_report_emails = item.report_emails.split('|')
    } catch (e) {
      console.log(e);
    } finally {
      return item;
    }
  }
}
