<template>
  <div>
    <h2 class="fw-bold mb-4">Status de aprovação</h2>
    <div class="row">
      <div class="col-12" v-if="item?.deleted_at">
        <div class="row mb-4">
          <div class="col-12">
            <span class="alert alert-danger d-flex justify-content-center mb-0">Conta excluída</span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="list-group list-group-flush my-n3">

              <!-- Account Status -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-toggle-on fa-xl"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Conta ativa
                    </h4>
                    <small class="text-body-secondary">
                      Psicólogo criou a conta em <strong> {{ item.formatted_created_at }}</strong>.
                    </small>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.active" class="badge text-bg-success fw-bold">
                      Ativa
                      <i class="fa-solid fa-check"></i>
                    </span>
                    <span v-else class="badge text-bg-light fw-bold">
                      Inativa
                      <i class="fa-regular fa-clock"></i>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Bank Filled -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-building-columns fa-xl"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Informações bancárias
                    </h4>
                    <small class="text-body-secondary">
                      Psicólogo preencheu as informações bancárias no cadastro.
                    </small>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.bank_account?.length" class="badge text-bg-success fw-bold">
                      Preenchido
                      <i class="fa-solid fa-check"></i>
                    </span>
                    <span v-else class="badge text-bg-light fw-bold">
                      Não preenchido
                      <i class="fa-regular fa-clock"></i>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Entry fee -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- <i class="fa-brands fa-cc-stripe fa-xl"></i> -->
                    <i class="fa-regular fa-money-bill-1 fa-xl"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Taxa de inscrição
                    </h4>
                    <small class="text-body-secondary">
                      Taxa de inscrição do psi no <a :href="`${item.entry_fee?.invoice_link}`" target="_blank"
                        v-if="item.entry_fee">Stripe <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                      <span v-else>
                        Stripe
                      </span>
                    </small>
                  </div>
                  <div class="col-auto">
                    <span v-if="!item.entry_fee" class="badge text-bg-light fw-bold">
                      N/A
                    </span>
                    <span v-else-if="item.entry_fee?.status == 'paid'" class="badge text-bg-success fw-bold">
                      Pago
                      <i class="fa-solid fa-check"></i>
                    </span>
                    <span v-else class="badge text-bg-light fw-bold">
                      Não pago
                      <i class="fa-regular fa-clock"></i>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Stripe Approval -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-brands fa-cc-stripe fa-xl"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Aprovação do Stripe
                    </h4>
                    <small class="text-body-secondary">
                      Aprovação da conta bancária no <a :href="`${$stripeBaseUrl}connect/accounts/${item.stripe_id}`"
                        target="_blank">Stripe <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                    </small>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.charges_enabled" class="badge text-bg-success fw-bold">
                      Aprovado
                      <i class="fa-solid fa-check"></i>
                    </span>
                    <span v-else class="badge text-bg-light fw-bold">
                      Não aprovado
                      <i class="fa-regular fa-clock"></i>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Admin Approval -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-solid fa-user-check fa-xl"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Aprovação do administrador
                    </h4>
                    <small class="text-body-secondary">
                      Aprovação da conta pelo administrador
                    </small>
                  </div>
                  <div class="col-auto">
                    <span v-if="item.approved" class="badge text-bg-success fw-bold">
                      Aprovado
                      <i class="fa-solid fa-check"></i>
                    </span>
                    <span v-else class="badge text-bg-light fw-bold">
                      Não aprovado
                      <i class="fa-regular fa-clock"></i>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Agenda Status -->
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-regular fa-calendar fa-xl"></i>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="fw-bold mb-1">
                      Agenda de horários
                    </h4>
                    <small class="text-body-secondary">
                      Disponibilidade para pacientes agendarem novas sessões
                    </small>
                  </div>
                  <div class="col-auto">
                    <span v-if="!item.block_agenda" class="badge text-bg-success fw-bold">
                      Disponível
                      <i class="fa-solid fa-check"></i>
                    </span>
                    <span v-else class="badge text-bg-light fw-bold">
                      Bloqueado
                      <i class="fa-solid fa-lock"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-4">

    <div class="d-flex justify-content-between">

      <h2 class="fw-bold mb-4">Provedor da chamada</h2>

      <span v-if="isSubmitting" class="spinner-border spinner-border-sm text-primary" role="status"
        aria-hidden="true"></span>
      <a v-else @click.prevent="updateCallProvider" class="fw-bold cursor-pointer"
        :class="{ 'text-primary': !isChangingCallProvider, 'text-danger': isChangingCallProvider }">
        {{
          isChangingCallProvider ? 'Cancelar' : 'Alterar'
        }}
      </a>
    </div>

    <div class="row">
      <div class="col-12">
        <SelectInput :model="$v.item.call_provider" :options="providerOptions"
          @selectChanged="handleChangeCallProvider($event)" v-if="isChangingCallProvider" />
        <TextInput :readOnly="true" :value="item.callProviderLabel" v-else />
      </div>
    </div>

    <hr class="my-4">

    <h2 class="fw-bold mb-4">Informações de cadastro</h2>
    <div class="row">
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="Email" :value="item.email" />
      </div>
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="CRP" :value="item.crp" />
      </div>
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="CPF" :value="item.document_number" />
      </div>
      <div class="col-12 col-xl-6">
        <TextInput :readOnly="true" label="Telefone" :value="item.phone" />
      </div>
      <div class="col-12 col-xl-12">
        <TextInput :readOnly="true" label="Endereço" :value="`${item.address_line1} ${item.address_line2}`" />
      </div>
      <div class="col-12 col-xl-4">
        <TextInput :readOnly="true" label="Cidade" :value="`${item.address_city}`" />
      </div>
      <div class="col-12 col-xl-4">
        <TextInput :readOnly="true" label="CEP" :value="`${item.address_postal_code}`" />
      </div>
      <div class="col-12 col-xl-4">
        <TextInput :readOnly="true" label="Estado" :value="`${item.address_state}`" />
      </div>
    </div>
    <hr class="my-4" />
    <h2 class="fw-bold mb-4">Sobre o aparelho</h2>
    <div class="row">
      <div class="col-12">
        <div class="list-group mb-4">
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Sistema operacional
                </p>
              </div>
              <div class="col-auto">
                <p v-if="item.device_details?.os === 'android'" class="fw-bold mb-0" style="color: #3DDC84;">
                  Android
                  <i class="fa-brands fa-android"></i>
                </p>
                <p v-if="item.device_details?.os === 'ios'" class="fw-bold mb-0">
                  iOS
                  <i class="fa-brands fa-apple"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Versão
                </p>
              </div>
              <div class="col-auto">
                <p class="fw-bold mb-0">
                  {{ item.device_details?.osVersion }}
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Modelo do aparelho
                </p>
              </div>
              <div class="col-auto">
                <p class="fw-bold mb-0">
                  {{ item.device_details?.model }}
                </p>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-start">
                <p class="text-muted mb-0">
                  Versão do Psiapp
                </p>
              </div>
              <div class="col-auto">
                <p class="fw-bold mb-0">
                  {{ item.device_details?.appVersion }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-4" />
    <h2 class="fw-bold mb-4" id="fotos_crp">Fotos do CRP

    </h2>
    <div class="row">
      <div class="col">
        <div @click="viewDocument(item.document_image)" class="card cursor-pointer lift">
          <img class="card-img-top" :src="`${$baseImageUrl}${item.document_image}`" alt="...">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="mb-1">
                  Foto do CRP de frente.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div @click="viewDocument(item.document_in_hand)" class="card cursor-pointer lift">
          <img class="card-img-top" :src="`${$baseImageUrl}${item.document_in_hand}`">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="mb-1">
                  Psicólogo segurando o CRP.
                </h4>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr v-if="item.bank_document_front" class="my-4" />
    <h2 class="fw-bold mb-4" v-if="item.bank_document_front">Fotos do documento</h2>
    <div class="row" v-if="item.bank_document_front">
      <div class="col" v-if="item.bank_document_front">
        <div @click="viewDocument(item.bank_document_front)" class="card cursor-pointer lift">
          <img class="card-img-top" :src="`${$baseImageUrl}${item.bank_document_front}`" alt="...">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="mb-1">
                  Foto do documento de frente.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col" v-if="item.bank_document_back">
        <div @click="viewDocument(item.bank_document_back)" class="card cursor-pointer lift">
          <img class="card-img-top" :src="`${$baseImageUrl}${item.bank_document_back}`">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="mb-1">
                  Foto do documento de trás.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr v-if="item.company_document_front" class="my-4" />
    <h2 class="fw-bold mb-4" v-if="item.company_document_front">Fotos do documento da empresa</h2>
    <div class="row" v-if="item.company_document_front">
      <div class="col" v-if="item.company_document_front">
        <div @click="viewDocument(item.company_document_front)" class="card cursor-pointer lift">
          <img class="card-img-top" :src="`${$baseImageUrl}${item.company_document_front}`" alt="...">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="mb-1">
                  Foto do documento de frente.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col" v-if="item.company_document_back">
        <div @click="viewDocument(item.company_document_back)" class="card cursor-pointer lift">
          <img class="card-img-top" :src="`${$baseImageUrl}${item.company_document_back}`">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="mb-1">
                  Foto do documento de trás.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PsiDetailRegisterInfo",
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      providerOptions: [{ id: 'agora', title: 'Agora' }, { id: 'whereby', title: 'Whereby' }],
      isChangingCallProvider: false,
      isSubmitting: false
    }
  },
  methods: {
    viewDocument(docUrl) {
      window.open(`${this.$baseImageUrl}${docUrl}`, '_blank');
    },
    async handleChangeCallProvider(value) {
      this.isSubmitting = true
      this.$emit("changeCallProvider", value);
      setTimeout(() => {
        this.isChangingCallProvider = false
        this.isSubmitting = false
      }, 1500);
    },
    updateCallProvider() {
      this.isChangingCallProvider = !this.isChangingCallProvider
    },
    validations: {
      item: {
        call_provider: ''
      }
    }
  }
}
</script>
