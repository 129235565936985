<template>
  <div id="add-coupon">
    <Navbar :current="title" :link="$paths.coupons.list" :linkName="'Empresas parceiras'" />
    <Sidebar />
    <Container>
      <Header :title="title" />
      <LoadingContainer v-if="loadingItems" />
      <template v-else>
        <div class="card">
          <div class="card-body">
            <h3>Informações</h3>
            <div class="row">
              <div class="col-12 col-md-12">
                <TextInput label="Nome da empresa" :required="true" :model="$v.form.company_name" :maxLength="100"
                  type="text" :errorMsg="' Informe um código com no mínimo 2 caractéres'" placeholder="Psiapp" />
              </div>
              <div class="col-12 col-md-12">
                <TextInput label="Desconto(%)" :model="$v.form.discount" :maxLength="3" type="text" placeholder="10%"
                  :errorMsg="'Informe a porcentagem de desconto entre 1% e 30%'" :mask="'##'" />
                <div class="bg-light rounded p-3 mb-4" style="margin-top: -10px;">
                  <small>Ao ativar a parceria com o código da empresa, não será possível para o paciente usar um cupom
                    de
                    desconto e o
                    benefício nas consultas simultaneamente.</small>
                </div>
              </div>
              <div class="col-12 col-md-12">
                <MoneyInput label="Valor máximo da sessão" :model="$v.form.max_session_cost"
                  placeholder="Valor máximo da sessão" :errorMsg="'Informe um valor válido'" />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body pb-0">
            <div class="row">
              <div class="col-12 mb-4">
                <div class="form-check form-switch">
                  <input class="form-check-input" v-model="$v.form.hasAppointmentValueLimit.$model" type="checkbox"
                    id="hasAppointmentValueLimit" />
                  <label class="form-label text-muted" for="freeCheckDefault">Limitar valor total do subsídio
                    em um período</label>
                </div>
              </div>
              <template v-if="$v.form.hasAppointmentValueLimit.$model">
                <div class="col-12 col-md-6">
                  <MoneyInput label="Valor máximo do subsídio" :model="$v.form.max_spending_amount"
                    placeholder="Valor máximo de cobertura" :errorMsg="'Informe um valor válido'" />
                </div>
                <div class="col-12 col-md-6">
                  <SelectInput label="Período de uso" :model="$v.form.spending_period" :options="periodSelect" />
                </div>
              </template>

            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body pb-0">
            <div class="row">
              <div class="col-12 mb-4">
                <div class="form-check form-switch">
                  <input class="form-check-input" v-model="$v.form.hasUserLimit.$model" type="checkbox"
                    id="hasUserLimit" />
                  <label class="form-label text-muted" for="freeCheckDefault">Limitar quantidade de sessões por usuário
                    em
                    um período</label>
                </div>
              </div>
              <template v-if="$v.form.hasUserLimit.$model">
                <div class="col-12 col-md-6">
                  <TextInput label="Quantidade de sessões por usuário" :model="$v.form.appointments_per_user"
                    :maxLength="3" type="text" :mask="'###'" :errorMsg="'A quantidade deve ser maior que 0'"
                    placeholder="Informe a quantidade de sessões" />
                </div>
                <div class="col-12 col-md-6">
                  <SelectInput label="Período de uso" :model="$v.form.user_limit_period" :options="periodSelect" />
                </div>
              </template>

            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="col">
              <h3>Códigos</h3>
            </div>
            <div class="col-auto">
              <button @click="toggleModal('add-partner-code')" class="btn btn-sm btn-secondary">Adicionar
                código</button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div v-if="form.codes.length" class="list-group list-group-flush">
                <div class="list-group-item" v-for="(code, index) in form.codes" :key="index">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <button @click="removeCode(index)" class="btn btn-sm btn-link text-danger"><span
                          class="fas fa-trash-alt"></span></button>
                    </div>
                    <div class="col">
                      <h4 class="mb-1 fw-bold text-secondary">
                        {{ code.title }}
                      </h4>
                      <p class="card-text small text-secondary">
                        <time>{{ code.code }}</time>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="col-6 offset-3">
                <div class="card card-inactive text-muted">
                  <div class="card-body">
                    <h3 class="text-center">
                      Nenhum código
                    </h3>
                    <p class="text-body-secondary text-center">
                      Adicione os códigos válidos para essa parceria.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="col">
              <h3>Emails para relatórios</h3>
            </div>
            <div class="col-auto">
              <!-- <button @click="toggleModal('add-partner-code')" class="btn btn-sm btn-secondary">Adicionar
                email</button> -->
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <IncrementTextInput msg="Informe o email e pressione enter para adicioná-lo na lista" label="Emails"
                :model="$v.form.email" placeholder="Informe um email" :errorMsg="'Informe um email válido'"
                @handleAddItem="addEmail" @handleRemoveItem="removeEmail" :items="form.report_emails" />
              <!-- <div v-if="form.codes.length" class="list-group list-group-flush">
                <div class="list-group-item" v-for="(code, index) in form.codes" :key="index">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <button @click="removeCode(index)" class="btn btn-sm btn-link text-danger"><span
                          class="fas fa-trash-alt"></span></button>
                    </div>
                    <div class="col">
                      <h4 class="mb-1 fw-bold text-secondary">
                        {{ code.title }}
                      </h4>
                      <p class="card-text small text-secondary">
                        <time>{{ code.code }}</time>
                      </p>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div v-else class="col-6 offset-3">
                <div class="card card-inactive text-muted">
                  <div class="card-body">
                    <h3 class="text-center">
                      Nenhum código
                    </h3>
                    <p class="text-body-secondary text-center">
                      Adicione os códigos válidos para essa parceria.
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <form>
          <div class="row">
            <div class="col-12 text-center">
              <span class="text-danger text-center">{{ errorMessage }}</span>
            </div>
          </div>
          <FormButton label="Concluir" :disabled="$v.$invalid" :loading="submitting" @onClick="submit" />
        </form>
      </template>
    </Container>
    <AddPartnerCode @onAdded="codeAdded" />
  </div>
</template>
<script>
import { toggleModal } from "@/components/ModalComponent.vue";
import AddPartnerController from "./AddPartnerController";
import LoadingContainer from "@/components/LoadingContainer.vue";
export default AddPartnerController;
</script>
<style scoped></style>
