import PsychologistsList from './PsychologistsList/PsychologistsList.vue'
export default {
  name: 'Psychologists',
  watch: {
    "$route.params"(value) {
      this.selectedTab = value.tab || 'todos'
    }
  },
  mounted() {

  },
  computed: {

    // entryFeeSelected() {
    //   console.log('wtf')
    //   return "wtf"
    // },

  },
  data() {
    return {
      entryFee: '', //nao entendi essa syntax
      // NOTE: Array de opções padrão de filtro de data
      entryFeeOptions: [
        {
          id: "all",
          title: "Todos",
        },
        {
          id: "paid",
          title: "Inscrição paga",
        },
        {
          id: "unpaid",
          title: "Inscrição não paga",
        },
      ],
      onlyPremium: false,
      tabs: [
        {
          id: 'todos',
          name: 'Todos',
          totalCount: 0
        },
        {
          id: 'aprovados',
          name: 'Aprovados',
          totalCount: 0
        },
        {
          id: 'reprovados',
          name: 'Não aprovados',
          totalCount: 0
        },
        {
          id: 'online',
          name: 'Agenda online',
          totalCount: 0
        },
        {
          id: 'imediata',
          name: 'Imediata',
          totalCount: 0
        },
      ],
      totalPsiCount: 0,
      selectedTab: this.$route.params.tab || 'todos',
    };
  },
  methods: {

    entryFeeChanged(event) {
      console.log('entryFeeChanged', event)
      console.log('entry fee', this.entryFee.$model)

      this.$eventBus.$emit("updatePsiList", { onlyPremium: this.onlyPremium, entryFeeFilter: event });


    },

    totalChanged(event, key) {
      let t = [...this.tabs]
      t.map(r => {
        if (r.id === key) {
          r.totalCount = event.total
        }
      })
      this.$set(this, 'tabs', t)
      if (!this.onlyPremium && key == 'todos') {
        this.totalPsiCount = event.total
      }
    },
    handlePremiumFilterChanged() {
      this.$eventBus.$emit("updatePsiList", { onlyPremium: this.onlyPremium });
    }
  },

  components: {
    PsychologistsList
  },
  validations: {
    entryFee: ''
  }
};
