import BarChart from "@/components/BarChart.vue"
import Container from "@/components/Container.vue"
import EmptyState from "@/components/EmptyState.vue"
import EditorInput from "@/components/FormComponents/EditorInput.vue"
import FormButton from "@/components/FormComponents/FormButton.vue"
import PasswordInput from "@/components/FormComponents/PasswordInput.vue"
import SelectInput from "@/components/FormComponents/SelectInput.vue"
import TextAreaInput from "@/components/FormComponents/TextAreaInput.vue"
import TextInput from "@/components/FormComponents/TextInput.vue"
import Header from "@/components/Header.vue"
import ImageCropper from "@/components/ImageCropper/ImageCropper.vue"
import VideoUploader from "@/components/VideoUploader/VideoUploader.vue"
import LoadingContainer from "@/components/LoadingContainer.vue"
import ModalComponent from "@/components/ModalComponent"
import Navbar from "@/components/Navbar.vue"
import RatingStars from "@/components/RatingStars/RatingStars.vue"
import CopyText from "@/components/CopyText.vue"
import SearchBar from "@/components/SearchBar.vue"
import Sidebar from "@/components/Sidebar.vue"
import Slider from "@/components/Slider.vue"
import TableHead from "@/components/TableHead.vue"
import UserService from '@/services/UserService'
import { EventBus } from '@/utils/EventBus'
import * as helper from '@/utils/Helpers'
import '@fortawesome/fontawesome-free/css/all.css'
import '@popperjs/core'
import 'bootstrap'
import 'jquery'
import * as moment from 'moment'
import 'quill/dist/quill.snow.css'
import VueMask from 'v-mask'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import './assets/theme/scss/theme.scss'
import router from './router'
import paths from './router/paths'
import pdf from 'vue-pdf'
import store from './store'
import './styles.scss'
import './utils/Prototypes/prototypeFunctions'
import money from 'v-money'


import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pt-br';

import ConfirmationAlert from "./components/ConfirmationAlert/ConfirmationAlert.vue"

import AppointmentsService from "./services/AppointmentsService"
import PostsService from "./services/PostsService"

import './utils/PreLoadedFunctions'
import CouponsService from './services/CouponsService'

import VueCompositionAPI from '@vue/composition-api'
import RatingsService from './services/RatingsService'
import VideoService from './services/VideoService'
import PartnerService from "./services/PartnerService"
import AppointmentTableItem from "./views/Appointments/AppointmentTableItem/AppointmentTableItem.vue"
import MoneyInput from "./components/FormComponents/MoneyInput.vue"
import IncrementTextInput from "./components/IncrementTextInput.vue"

Vue.use(VueQuillEditor)
Vue.use(Vuelidate)
Vue.use(VueMask);
Vue.use(money);
Vue.use(VueCompositionAPI)
Vue.config.productionTip = false


//services
Vue.prototype.$helper = helper
Vue.prototype.$userService = new UserService()
Vue.prototype.$postsService = new PostsService()
Vue.prototype.$partnerService = new PartnerService()
Vue.prototype.$couponsService = new CouponsService()
Vue.prototype.$appointmentsService = new AppointmentsService()
Vue.prototype.$ratingsService = new RatingsService()
Vue.prototype.$videoService = new VideoService()

//components
Vue.component('Datepicker', Datepicker)
Vue.component('Slider', Slider)
Vue.component('Sidebar', Sidebar)
Vue.component('Navbar', Navbar)
Vue.component('LoadingContainer', LoadingContainer)
Vue.component('EmptyState', EmptyState)
Vue.component('Container', Container)
Vue.component('SearchBar', SearchBar)
Vue.component('TextInput', TextInput)
Vue.component('MoneyInput', MoneyInput)
Vue.component('SelectInput', SelectInput)
Vue.component('IncrementTextInput', IncrementTextInput)
Vue.component('BarChart', BarChart)
Vue.component('TextAreaInput', TextAreaInput)
Vue.component('PasswordInput', PasswordInput)
Vue.component('EditorInput', EditorInput)
Vue.component('FormButton', FormButton)
Vue.component('TableHead', TableHead)
Vue.component('ImageCropper', ImageCropper)
Vue.component('VideoUploader', VideoUploader)
Vue.component('ModalComponent', ModalComponent)
Vue.component('Header', Header)
Vue.component('pdf', pdf)
Vue.component('ConfirmationAlert', ConfirmationAlert)
Vue.component('RatingStars', RatingStars)
Vue.component('CopyText', CopyText)
Vue.component('AppointmentTableItem', AppointmentTableItem)

//utils
Vue.prototype.$paths = paths
Vue.prototype.$eventBus = EventBus
Vue.prototype.$moment = moment
Vue.prototype.$envString = process.env.VUE_APP_ENV_STRING
Vue.prototype.$baseImageUrl = process.env.VUE_APP_IMG_BASE_URL
Vue.prototype.$stripeBaseUrl = process.env.VUE_APP_STRIPE_BASE_URL
Vue.prototype.$appEnv = process.env.VUE_APP_ENV


Vue.prototype.$versionString = process.env.VUE_APP_VERSION_STRING


// Vue.prototype.$pusher = new WebSocketService().getInstance()

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
  return formatter.format(value);
});
Vue.filter('getWeekDay', function (value) {
  if (typeof value !== "string") {
    return value;
  }
  switch (value) {
    case 'monday':
      return 'Segunda-feira'
    case 'tuesday':
      return 'Terça-feira'
    case 'wednesday':
      return 'Quarta-feira'
    case 'thursday':
      return 'Quinta-feira'
    case 'friday':
      return 'Sexta-feira'
    case 'saturday':
      return 'Sábado'
    case 'sunday':
      return 'Domingo'

    default: return value
  }
});



//prototype funcions


function capitalizeFirstPrototype(str) {
  if (!str) { str = this; }
  return str.charAt(0).toUpperCase() + str.slice(1);
}
function limitStringPrototype(limit, str) {
  try {
    if (!str) { str = this; }
    console.log('aqui')
    return str.length >= limit ? `${str.substr(0, limit - 1)}...` : str
  } catch (e) {
    console.log('aqui2', e)
    return str
  }
}
String.prototype.capitalizeFirst = capitalizeFirstPrototype
String.prototype.limitString = limitStringPrototype

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
