<template>
  <div id="users_detail">
    <Navbar
      current="Detalhes do paciente"
      :link="$paths.users.list"
      :linkName="'Pacientes'"
    />
    <Sidebar />
    <Container>
      <Header title="Detalhes do Paciente">
        <template v-slot:button>
          <template v-if="!loading && !item.deleted_at">
            <a
              :href="
                'https://api.whatsapp.com/send?phone=55' +
                $helper.cleanValue(item.phone) + '&text&type=phone_number&app_absent=0'
              "
              target="_blank"
              type="button"
              class="btn btn-success ms-3"
            >
              <i class="fa-brands fa-whatsapp"></i>
              Enviar mensagem
            </a>

            <button
              @click="freeAppointment()"
              class="btn btn-outline-secondary ms-3"
            >
              Sessão Gratuita
            </button>

            <button
              v-if="item.active"
              @click="changeActive()"
              class="btn btn-outline-secondary ms-3"
            >
              <i class="fa-solid fa-lock"></i>
              Inativar
            </button>
            <button v-else @click="changeActive()" class="btn btn-light ms-3">
              <i class="fa-solid fa-lock-open"></i>
              Ativar
            </button>

            <a class="btn btn-primary lift ms-3" :href="`${$paths.users.edit}/${id}`">   
              Editar
              <i class="fa-solid fa-pen me-1"></i> 
            </a>
          </template>
          <template v-else>
            <button class="btn btn-primary ms-3" @click="reactivateUser()"> Reativar conta</button>


          </template>
        </template>


      </Header>
      <LoadingContainer v-if="loading" />
      <div v-else>
                

        <div class="row my-4" v-if="item.deleted_at">
          <div class="col-12">
            <span class="alert alert-danger d-flex justify-content-center mb-0"
              >Conta excluída</span
            >

          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h3>Informações do paciente</h3>
              <div class="col-12 col-xl-12 mb-2">
                <img
                  src="@/assets/img/avatar.png"
                  alt="..."
                  class="avatar-img avatar-lg rounded-circle avatar-sm"
                />
                <span class="text-muted ps-3">{{ item.name }}   <a :href="item.stripe_link" target="_blank" v-if="item.stripe_link">
                  <i class="fa-brands fa-cc-stripe fa-xl text-dark" style="margin-left: 5px"></i>
                </a> </span>
              </div>
              <div class="col-12 col-xl-4">
                <TextInput
                  :readOnly="true"
                  label="Apelido"
                  :value="item.nickname"
                />
              </div>
              <div class="col-12 col-xl-5">
                <TextInput :readOnly="true" label="Email" :value="item.email" />
              </div>
              <div class="col-12 col-xl-3">
                <TextInput
                  :readOnly="true"
                  label="CPF"
                  :mask="'###.###.###-##'"
                  :value="item.document_number"
                />
              </div>
              
              <div class="col-12 col-xl-6">
                <TextInput
                  :readOnly="true"
                  label="Telefone"
                  :value="item.phone"
                />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput
                  :readOnly="true"
                  label="Data de criação"
                  :value="item.formatted_created_at"
                />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput
                  :readOnly="true"
                  label="Celular"
                  :value="`${item.device_details?.os} - ${item.device_details?.model} ${item.device_details?.osVersion}`"
                />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput
                  :readOnly="true"
                  label="Versao do psiapp"
                  :value="`${item.device_details?.appVersion}`"
                />
              </div>

              <h3>Jornada do paciente</h3>

              <div class="row">
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-body text-center small">
                      <i class="mb-3 fa-solid fa-circle-check text-success me-2" v-if="item.milestones?.viewed_home"></i>
                      <i class="mb-3 fa-solid fa-clock text-muted" v-else></i><br>
                      Viu a home
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-body text-center small">
                      <i class="mb-3 fa-solid fa-circle-check text-success me-2" v-if="item.milestones?.searched_psys"></i>
                      <i class="mb-3 fa-solid fa-clock text-muted" v-else></i><br>
                      Buscou psis
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-body text-center small">
                      <i class="mb-3 fa-solid fa-circle-check text-success me-2" v-if="item.milestones?.viewed_psy"></i>
                      <i class="mb-3 fa-solid fa-clock text-muted" v-else></i><br>
                      Visualizou um psi
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-body text-center small">
                      <i class="mb-3 fa-solid fa-circle-check text-success me-2" v-if="item.milestones?.viewed_calendar"></i>
                      <i class="mb-3 fa-solid fa-clock text-muted" v-else></i><br>
                      Viu calendário
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-body text-center small">
                      <i class="mb-3 fa-solid fa-circle-check text-success me-2" v-if="item.milestones?.viewed_times"></i>
                      <i class="mb-3 fa-solid fa-clock text-muted" v-else></i><br>
                      Viu horários
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-body text-center small">
                      <i class="mb-3 fa-solid fa-circle-check text-success me-2" v-if="item.milestones?.credit_card_registered"></i>
                      <i class="mb-3 fa-solid fa-clock text-muted" v-else></i><br>
                      Cadastrou cartão
                    </div>
                  </div>
                </div>

                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-body text-center small">
                      <i class="mb-3 fa-solid fa-circle-check text-success me-2" v-if="item.milestones?.checked_coupon"></i>
                      <i class="mb-3 fa-solid fa-clock text-muted" v-else></i><br>
                      Aplicou cupom
                    </div>
                  </div>
                </div>

                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-body text-center small">
                      <i class="mb-3 fa-solid fa-circle-check text-success me-2" v-if="item.milestones?.created_appointment"></i>
                      <i class="mb-3 fa-solid fa-clock text-muted" v-else></i><br>
                      Tentou sessão
                    </div>
                  </div>
                </div>

                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-body text-center small">
                      <i class="mb-3 fa-solid fa-circle-check text-success me-2" v-if="item.milestones?.concluded_appointment"></i>
                      <i class="mb-3 fa-solid fa-clock text-muted" v-else></i><br>
                      Concluiu sessão
                    </div>
                  </div>
                </div>

              </div>

              <h3>Recorrências</h3>

              <div class="row">
                <p v-if="!item.recurrencies?.length" class="mb-3">Nenhuma recorrência</p>

                <hr v-if="!item.recurrencies?.length">

                
                  <div v-for="(recurrency, index) in item.recurrencies" :key="index" class="card mb-3 col-4">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">


                          <div class="row">
                            <div class="col-lg-4 p-3"> 
                              
                              <img  class="avatar-img rounded-circle" :src="`${$baseImageUrl}${recurrency.psychologist?.profile_image}`" alt="" >
                           </div>
                            <div class="col-lg-8">
                              <h5 class="card-title mb-1">
                            
                                <strong>  Psi {{ recurrency.psychologist?.name }}</strong> </h5>
                              <div class="small">
                     
                                R$ {{ recurrency.value }} 
      
      
                                  <br> <span class="fw-bold mb-0" >
                                    <i class="fa-solid fa-circle fa-xs text-success me-2" v-if="recurrency.status == 'active'"></i>
                                    <i class="fa-solid fa-circle-xmark text-danger me-2" v-else></i>
                                    <span v-if="recurrency.frequency == 'weekly' && recurrency.interval == 1"> Semanal</span>
                                    <span v-else-if="recurrency.frequency == 'weekly' && recurrency.interval == 2"> Quinzenal</span>
                            
                                    <span v-else-if="recurrency.frequency == 'monthly' && recurrency.interval == 1"> Mensal</span>
                            
                                    <span v-else> {{ recurrency.frequency }} Interval {{recurrency.interval}}</span>
                                  </span>
                                </div>

                            </div>

                          </div>

                      </div>
                    </div>
                  </div>
              </div>

              <h3>Sessões</h3>
              <div class="col-12">
                <!-- v-on:totalItemsChanged="totalChanged($event, 'agendadas')" -->
                <AppointmentList
                  :propFilters="{
                    user_id: id
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <ModalComponent :title="'Sessão Gratuita'" id="freeAppointmentModal">
      <template v-slot:body>
        <div class="row">
          <div class="col-12 col-md-6">
            <TextInput
              label="Paciente"
              :value="item.nickname"
              :readOnly="true"
            />
          </div>
          <div class="col-12 col-md-6">
            <TextInput
              label="Data e horário"
              type="datetime-local"
              :model="$v.freeAppointmentForm.date"
              placeholder="Data e horário"
            />
          </div>
        </div>

        <p class="form-label text-muted">Psicólogo</p>
        <div class="card mb-2">
          <div class="card-body py-0">
            <SearchBar @onSearch="search" :isCard="false" />
          </div>
        </div>
        <div class="card card-custom mb-0">
          <div class="card-body">
            <LoadingContainer v-if="loadingItems" />

            <div
              class="d-flex border-bottom pb-3 mb-3 align-items-center justify-content-between cursor-pointer"
              v-for="(psychologist, index) of items"
              :key="index"
              @click="selectPsi(psychologist)"
            >
              <div class="d-flex align-items-center">
                <div
                  class="avatar"
                  v-if="
                    psychologist.profile_image &&
                    psychologist.profile_image != '1'
                  "
                >
                  <img
                    :src="`${$baseImageUrl}${psychologist.profile_image}`"
                    alt="..."
                    class="avatar-img avatar-img-sm rounded-circle"
                  />
                </div>
                <div v-else class="avatar avatar-sm">
                  <img
                    src="@/assets/img/avatar_placeholder.svg"
                    alt="..."
                    class="avatar-img rounded-circle"
                  />
                </div>
                <div class="ms-2">
                  <span class="fw-bold">
                    {{ psychologist.name }}
                    <i
                      v-if="psychologist.subscription"
                      class="fa-solid fa-circle-plus text-info"
                    ></i>
                  </span>
                  <span
                    class="small fw-bold text-danger"
                    v-if="psychologist.deleted_at"
                    >(Excluído)</span
                  >
                  <br />
                  <span>
                    {{ psychologist.email }}
                  </span>
                </div>
              </div>
              <i
                class="fa-solid fa-check-square text-primary"
                v-if="
                  $v.freeAppointmentForm.psychologist_id.$model ==
                  psychologist?.id
                "
              />
              <i class="fa-regular fa-square text-muted" v-else />
            </div>
            <FormButton
              v-if="!loadingItems && moreItemsToLoad"
              class="btn-link"
              label="Carregar mais"
              :loading="loadingMoreItems"
              @onClick="loadMore"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <FormButton
          label="Concluir"
          :loading="submitting"
          :disabled="$v.freeAppointmentForm.$invalid"
          class="btn w-15 btn-primary mb-3"
          @onClick="handleFreeAppointment"
        />
      </template>
    </ModalComponent>
    <ModalComponent :title="'Alterar email'" id="changeEmailModal">
      <template v-slot:body>
        <div class="mt-3">
          <TextInput
            label=""
            :model="$v.form.email"
            class="text-white"
            placeholder="Digite o novo email"
          />
          <p class="text-center text-danger">
            {{ errorMessage }}
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <FormButton
            label="Concluir"
            :loading="submitting"
            :disabled="$v.form.$invalid"
            class="btn w-15 btn-primary mb-3"
            @onClick="handleChangeEmail"
          />
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import UserDetailController from "./UserDetailController";
export default UserDetailController;
</script>
<style scoped>
.card-custom {
  height: calc(100vh - 500px);
  overflow: scroll;
}
</style>
