import moment from 'moment'

export default class PayoutService {
  getPayoutStatus(payoutStatus) {
    switch (payoutStatus) {
      case 'paid':
        return {
          title: 'Realizado',
        }
      case 'failed':
        return {
          title: 'Falha',
        }
      case 'canceled':
        return {
          title: 'Cancelado',
        }
      case 'in_transit':
        return {
          title: 'Em trânsito',
        }
      case 'pending':
        return {
          title: 'Aguardando',
        }
      default:
        return {
          title: 'N/A',
        }
    }
  }
  preparePayout(payout) {

    return {
      date: moment(payout.arrival_date).format('DD/MM/YYYY'),
      amount: payout.amount.toCurrency(),
      statusObject: this.getPayoutStatus(payout.status),
    }
  }
  preparePayoutTransaction(transaction) {

    return {
      available_on: moment(transaction.available_on).format('DD/MM/YYYY'),
      net: transaction.net.toCurrency(),
    }
  }
}