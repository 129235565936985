import AppointmentList from "./AppointmentList/AppointmentList.vue";
export default {
  name: "Appointments",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.setupDateFilter();
  },
  watch: {
    "$route.params"(value) {
      this.selectedTab = value.tab || "agendadas";
    },
  },
  computed: {
    dateRangeSelected() {
      if (
        !this.dateRange ||
        this.dateRange[0] === "" ||
        this.dateRange[1] === ""
      ) {
        return "all";
      }

      let range = this.dateRange
        .map((elem) => this.$moment(elem).format("YYYY-MM-DD"))
        .join();
      let today = [
        this.$moment().format("YYYY-MM-DD"),
        this.$moment().format("YYYY-MM-DD"),
      ].join();
      let month = [
        this.$moment().startOf("month").format("YYYY-MM-DD"),
        this.$moment().endOf("month").format("YYYY-MM-DD"),
      ].join();

      if (range == today) {
        return "today";
      } else if (range == month) {
        return "month";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      // NOTE: Criei essa variável para manipular as datas do filtro. Caso tenha na url eu pego o valor da mesma. (A lib precisa que seja uma data JS, por isso que eu converto.)
      dateRange: null,
      // NOTE: Array de opções padrão de filtro de data
      dateRangeOptions: [
        {
          id: "all",
          title: "Todas",
        },
        {
          id: "today",
          title: "Hoje",
        },
        {
          id: "month",
          title: "Este mês",
        },
      ],
      tabs: [
        {
          id: "agendadas",
          name: "Agendadas",
          totalCount: 0,
        },
        {
          id: "online",
          name: "Online",
          totalCount: 0,
        },
        // {
        //   id: "imediatas",
        //   name: "Imediatas",
        //   totalCount: 0,
        // },
        {
          id: "andamento",
          name: "Em andamento",
          totalCount: 0,
        },
      ],
      selectedTab: this.$route.params.tab || "agendadas",
    };
  },
  methods: {
    totalChanged(event, key) {
      let t = [...this.tabs];
      t.map((r) => {
        if (r.id === key) {
          r.totalCount = event.total;
        }
      });
      this.$set(this, "tabs", t);
    },
    // NOTE: Função para setar o dateRange conforme a escolha do select
    dateOptionsChanged(event) {
      switch (event) {
        case "month":
          this.dateRange = [
            this.$moment().startOf("month").toDate(),
            this.$moment().endOf("month").toDate(),
          ];
          break;
        case "today":
          this.dateRange = [this.$moment().toDate(), this.$moment().toDate()];
          break;
        case "all":
          this.dateRange = "";
          break;
      }
    },
    setupDateFilter() {
      let dateStart = this.$route.query.date_start;
      let dateEnd = this.$route.query.date_end;

      if (dateStart && dateEnd) {
        this.dateRange = [
          this.$moment(dateStart).toDate(),
          this.$moment(dateEnd).toDate(),
        ];
      } else if (dateStart === "" && dateEnd === "") {
        this.dateRange = [dateStart, dateEnd];
      } else {
        this.dateRange = [
          this.$moment().startOf("month").toDate(),
          this.$moment().endOf("month").toDate(),
        ];
      }
    },
    handleChangeTab(tab) {
      let query = {
        ...this.$route.query,
        date_start: this.$moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_end: this.$moment(this.dateRange[1]).format("YYYY-MM-DD"),
      };

      this.$router.push({
        path: `${this.$paths.appointments.list}/${tab.id}`,
        query,
      });
    },
  },
  components: {
    AppointmentList,
  },
  validations: {
    dateRangeSelected: ''
  }
};
