import HomeCard from './HomeCard.vue'
export default {
  name: "Home",
  mounted() {
    this.loadingItems = true
    this.setupDateFilter()
    this.getGraphics()
  },
  data() {
    return {
      loadingItems: false,
      info: {
        users: 0,
        admins: 0,
      },
      currentMonth: this.$moment().locale("pt").format('MMMM'),
      chartData: {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        dataSets: []
      },
      filters: {
        date_start: '',
        date_end: ''
      },
      dateRange: null,
      dateRangeOptions: [
        {
          id: 'all', title: 'Todas'
        },
        {
          id: 'today', title: 'Hoje'
        },
        {
          id: 'month', title: 'Este mês'
        }
      ],
    }
  },
  watch: {
    dateRange(value) {
      this.filters = {
        ...this.filters,
        date_start: value[0] ? this.$moment(value[0]).format('YYYY-MM-DD') : '',
        date_end: value[1] ? this.$moment(value[1]).format('YYYY-MM-DD') : ''
      }

      delete (this.$route.query.date_start)
      delete (this.$route.query.date_end)

      let query = {
        ...this.$route.query,
        date_start: this.filters.date_start,
        date_end: this.filters.date_end
      }

      this.$router.replace({ query })

      this.getData()
    },
  },
  computed: {
    dateRangeSelected() {
      if (!this.dateRange || this.dateRange[0] === '' || this.dateRange[1] === '') {
        return 'all'
      }

      let range = this.dateRange.map(elem => this.$moment(elem).format('YYYY-MM-DD')).join()
      let today = [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')].join()
      let month = [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().endOf('month').format('YYYY-MM-DD')].join()

      if (range == today) {
        return 'today'
      } else if (range == month) {
        return 'month'
      } else {
        return ''
      }
    }
  },
  methods: {
    setupDateFilter() {
      let dateStart = this.$route.query.date_start
      let dateEnd = this.$route.query.date_end

      if (dateStart && dateEnd) {
        this.dateRange = [this.$moment(dateStart).toDate(), this.$moment(dateEnd).toDate()]
      } else if (dateStart === '' && dateEnd === '') {
        this.dateRange = [dateStart, dateEnd]
      } else {
        this.dateRange = [this.$moment().startOf('month').toDate(), this.$moment().endOf('month').toDate()]
      }
    },
    async getData() {
      try {
        let res = await this.$userService.listStatistics(this.filters)
        this.$set(this, 'info', res.data)
      } catch (error) {
        console.log('ERRO NO GET DATA NA HOME', error)
      } finally {
        this.loadingItems = false
      }
    },
    async getGraphics() {
      try {
        let res = await this.$userService.listAppointmentGraphics()
        this.$set(this, 'chartData',
          {
            labels: res.data.map(r => this.$moment(r.date).locale('pt').format('DD/MMM')).reverse(),
            dataSets: [
              {
                label: "Imediatas",
                data: res.data.map(r => r.appointments_imediate_concluded).reverse(),
                backgroundColor: '#2e4664ba',
                barThickness: 10,
                barRadius: 20,
              },
              {
                label: "Agendadas",
                data: res.data.map(r => r.appointments_scheduled_concluded).reverse(),
                backgroundColor: '#F3BAA6',
                barThickness: 10,
                barRadius: 20,
              },
            ]
          })
      } catch (e) {
        console.log('ERRO NO GET GRAPHICS DA HOME', e)
      } finally {
      }
    },
    dateOptionsChanged(event) {
      switch (event) {
        case 'month':
          this.dateRange = [this.$moment().startOf('month').toDate(), this.$moment().endOf('month').toDate()]
          break;
        case 'today':
          this.dateRange = [this.$moment().toDate(), this.$moment().toDate()]
          break;
        case 'all':
          this.dateRange = ''
          break;
      }
    },
  },
  components: {
    HomeCard
  },
  validations: {
    dateRangeSelected: ''
  }
};
