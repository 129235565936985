const paths = {
  not_found: '/not-found',
  login: '/login',
  forgot_password: '/esqueci-a-senha',
  password_update: '/editar-senha',
  home: '/home',
  ratings: {
    list: '/avaliacoes',
    detail: '',
    reported: '/avaliacoes-reportadas'
  },
  users: {
    list: '/pacientes',
    detail: '/pacientes/detalhes',
    edit: '/pacientes/editar',
  },
  appointments: {
    list: '/sessoes',
    detail: '/sessoes/detalhes',
    scheduled: '/sessoes/agendadas',
    imediate: '/sessoes/imediatas',
    ongoing: '/sessoes/andamento',
  },
  posts: {
    list: '/posts',
    detail: '/posts/detalhes',
    add: '/posts/adicionar',
    edit: '/posts/editar',
  },
  reports: {
    list: '/denuncias',
    details: '/denuncias/detalhes',
  },
  psychologists: {
    list: '/psicologos',
    detail: '/psicologos/detalhes',
    edit: '/psicologos/editar',
  },
  coupons: {
    list: '/codigos',
    detail: '/codigos/detalhes',
    add: '/codigos/adicionar',
  },
  partners: {
    list: '/empresas-parceiras',
    detail: '/empresas-parceiras/detalhes',
    add: '/empresas-parceiras/adicionar',
    edit: '/empresas-parceiras/editar',
  },
  videos: {
    list: '/videos-home',
    detail: '/videos-home/detalhes',
    add: '/videos-home/adicionar',
  },
  admins: {
    list: '/administradores',
    add: '/administradores/adicionar',
    edit: '/administradores/editar',
    detail: '/administradores/detalhes',
    password_update: '/editar-senha/administradores',
  },
  contracts: {
    list: '/contratos',
    userTerms: '/contratos/termos-usuario',
    psiTerms: '/contratos/termos-psicologos',
    privacyPolicy: '/contratos/politica-privacidade',
  },
  crashes: {
    list: '/crashes',
    detail: '/crashes/detalhes',
  },
}

export default paths
