import DetailMixinController from "@/mixins/DetailMixinController";
//TODO: formatar o periodo para mostrar certo em portugues la no html
//TODO: fazer o CI do jeito novo
export default {
  mixins: [DetailMixinController],
  mounted() {
    this.hasDelete = true;
  },
  data() {
    return {
      appointmentColumns: [
        {
          name: "ID",
          sortName: null,
          order: null,
        },
        {
          name: "Psicólogo",
          sortName: null,
          order: null,
        },
        {
          name: "Paciente",
          sortName: null,
          order: null,
        },
        {
          name: "Horário",
          sortName: null,
          order: null,
        },
        {
          name: "Status",
          sortName: null,
          order: null,
        },
        {
          name: "Atalho",
          sortName: null,
          order: null,
        },
      ],
      userColumns: [
        {
          name: "Nome",
          sortName: null,
          order: null,
        },
        {
          name: "Atalho",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    async fetchPromise() {
      return this.$partnerService.show(this.id);
    },
    async deletePromise() {
      return this.$partnerService.delete(this.id);
    },
    prepareResponse(data) {
      /**
       *
       * //TODO: mano, nao ta vindo o "sessoes realizadas" e nem o "pacientes com codigo ativo" no show ainda... deixei abaixo o que fazer quando vier..
       * da uma olhada no componente AppointmentTableItem qqr coisa tbm... modularizei pra usar nas listas de appointment e to usando aqui e no AppointmentList
       * */

      //TODO: fazer o prepareAppointment com as sessoes que vao vir no obj com o nome certo e se vier diferente de appointments, precisa trocar no html
      data.appointments = data.appointments.map((elem) =>
        this.$appointmentsService.prepareAppointment(elem)
      );

      //TODO: nao sei com que nome vai vir o "pacientes com codigo ativo".. coloquei como users no html
      // data.patients = [];
      return this.$partnerService.preparePartner(data);
    },
  },
};
