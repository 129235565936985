import { defineComponent } from 'vue'

export default defineComponent({
    name: 'FormMixinController',
    props: {
        id: {
            type: String,
            default: ""
        },
    },
    computed: {
        isAdding() {
            return !this.id
        },
    },
    mounted() {
        if (!this.isAdding) {
            this.getData()
        } else {
            this.loadingItems = false
        }
    },
    data() {
        return {
            redirectPath: '',
            errorMessage: '',
            submitting: false,
            loadingItems: true,
            fieldError: ''
        }
    },
    methods: {
        async getData() {
            try {
                const res = await this.fetchPromise()
                this.setupForm(res.data)
            } catch (e) {
                console.log(e)
            } finally {
                this.loadingItems = false
            }
        },
        setupForm(data) {
            return data
        },
        fetchPromise() {
            throw new Error('Método fetchPromise não implementado')
        },
        async submit() {
            try {
                this.submitting = true
                const res = await this.handleSubmit()
                console.log('resposta da api', this.isAdding)
                if (res.status) {
                    if (this.isAdding) {
                        this.$eventBus.$emit('showToast', { title: 'Item criado', msg: `Item criado com sucesso` })
                        console.log(`aeeeeeeeeee ${this.redirectPath}/${res.data.id}`)
                        if (this.redirectPath === null) {
                            this.$router.back()
                        } else {
                            this.$router.push(`${this.redirectPath}/${res.data.id}`)
                        }
                    } else {
                        console.log(`aeeeeeeeeee  ${this.redirectPath}/${this.id}`)
                        this.$eventBus.$emit('showToast', { title: 'Item alterado', msg: `Item alterado com sucesso` })
                        if (this.redirectPath == null) {
                            this.$router.back()
                        } else {
                            this.$router.push(`${this.redirectPath}`)
                        }
                    }
                }
            } catch (e) {
                console.log(e)
                this.$eventBus.$emit('showErrorToast', { msg: e.message })
            } finally {
                this.submitting = false
            }
        },
        handleSubmit() { },
    }
})
